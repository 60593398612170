<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      
      <div class="navbar-minimize">
        <button (click)="minimizeSidebar()" class="btn btn-just-icon">
          <i class="material-icons text_align-center visible-on-sidebar-regular">view_week</i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
        </button>
      </div>
      
    </div>
    

    <div class="collapse navbar-collapse justify-content-start">
      <ng-container *ngFor="let item of Selectedmenu">
        <div *ngIf="0 == item.parent_id && isActive(item)" class="navbar-minimize" >
          <a class="{{ item.id == activemenu ? 'active' : '' }}" [routerLink]="[item.path]" >{{item.name}}</a>
        </div>
      </ng-container>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div  class="collapse navbar-collapse justify-content-end" id="navigation">
      <div class="nav-item dropdown mr-2" *ngIf="userdetails.role_id != 1">
        <a class="nav-link" href="javascript:void(0)" (click)="dropdownshownew()" data-toggle="dropdown" id="navbarDropdownMenuLink1" aria-haspopup="true" aria-expanded="false">
          <img width="20px" src="./assets/img/add-43.png" class="img">
        </a>
        <div id="navbarDropdownpopupnew" class="dropdown-menu dropdown-menu-right"  aria-labelledby="navbarDropdownMenuLink1">
          <a class="dropdown-item" data-toggle="modal" data-target="#opennewmodal" (click)="getAccounts()" href="javascript:void(0)">Send Message</a>
          <a *ngIf="userdetails.merchant_id && userdetails.merchant_id != null && userdetails.merchant_id != '' && userdetails.is_verified_gateway && userdetails.is_verified_gateway == 1" class="dropdown-item"  data-toggle="modal" data-target="#opennewmodalforpayment" (click)="getAccounts()" href="javascript:void(0)">Send Payment link</a>
        </div>
      </div>
      <div class="navbar-minimize">
        <button class="btn btn-just-icon" title="Call" (click)="opentelephone()">
          <span class="material-symbols-outlined">
            <!-- <img width="20px" src="./assets/img/telephone.png" class="img"> -->
            <i style="margin: 3px 0 0 0;" class="fa fa-phone"></i> 
            </span>
          </button>
      </div>
      <div *ngIf="userdetails.role_id == 2" class="navbar-minimize" [routerLink]="['/settings']">
        <button class="btn btn-just-icon" title="Settings">
          <img width="20px" src="./assets/img/setting.png" class="img">
        </button>
      </div>
      <div *ngIf="userdetails.role_id != 1" class="navbar-minimize" [routerLink]="['/conversation']">
        <button class="btn btn-just-icon" title="Conversation">
          <i class="material-icons fa fa-comments-o"></i>
        </button>
        <span id="reviewCount" *ngIf="sharedService.totalUnreadConversation && userdetails.role_id != 1> 0" class="badge badge-pill badge-info" >{{sharedService.totalUnreadConversation}}</span>
      </div>

     
      <ul class="navbar-nav">
        
        <!-- <li class="nav-item dropdown mr-2" *ngIf="userdetails.role_id != 1">
          <a class="nav-link" href="javascript:void(0)" (click)="dropdownshownew()" data-toggle="dropdown" id="navbarDropdownMenuLink1" aria-haspopup="true" aria-expanded="false">
            <img width="20px" src="./assets/img/add-43.png" class="img">
          </a>
          <div id="navbarDropdownpopupnew" class="dropdown-menu dropdown-menu-right"  aria-labelledby="navbarDropdownMenuLink1">
            <a class="dropdown-item" data-toggle="modal" data-target="#opennewmodal" (click)="getAccounts()" href="javascript:void(0)">Send Message</a>
            <a *ngIf="userdetails.merchant_id && userdetails.merchant_id != null && userdetails.merchant_id != '' && userdetails.is_verified_gateway && userdetails.is_verified_gateway == 1" class="dropdown-item"  data-toggle="modal" data-target="#opennewmodalforpayment" (click)="getAccounts()" href="javascript:void(0)">Send Payment link</a>
          </div>
        </li> -->

        <li class="nav-item dropdown">
          <a class="nav-link" href="javascript:void(0)" (click)="dropdownshow()" data-toggle="dropdown" id="navbarDropdownMenuLink" aria-haspopup="true" aria-expanded="false">
              <h6 class="m-0" *ngIf="userdetails.role_id != 1"><b id="accountTitle" ><span class="mr-2"><img width="17px" src="./assets/img/user-icon.png" class="img"></span>{{userdetails.title}}</b></h6>
              <h6 class="m-0" *ngIf="userdetails.role_id == 1"><b id="accountTitle" ><span class="mr-2"><img width="17px" src="./assets/img/user-icon.png" class="img"></span>Super Admin</b></h6>
            
          </a>
          <div id="navbarDropdownpopup" class="dropdown-menu dropdown-menu-right"  aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" [routerLink]="['/profile']" href="javascript:void(0)"><i class="material-icons">person</i> &nbsp;&nbsp;{{ userdetails.role_id == 1 ? 'Profile/Settings' : 'Profile'}}</a>
            <a class="dropdown-item" *ngIf="userdetails.role_id == 2" [routerLink]="['/settings']" href="javascript:void(0)"><i class="material-icons">settings</i> &nbsp;&nbsp;Settings</a>
            <a class="dropdown-item" *ngIf="userdetails.role_id == 2" [routerLink]="['/billing-plan']" href="javascript:void(0)"><i class="material-icons">attach_money</i> &nbsp;&nbsp;Billing Plan</a>
            <a class="dropdown-item" (click)="logout()" href="javascript:void(0)"><i class="material-icons">logout</i> &nbsp;&nbsp;Logout</a>
          </div>
        </li>
        
      </ul>
    </div>
  </div>
</nav>

<!--open new message  Form Model -->
<div class="modal fade" id="opennewmodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content" >
        <form class="form-horizontal" [formGroup]="MessageForm" (ngSubmit)="onSubmit()" >
          <div class="modal-header">
            <h4 class="modal-title">Send Message</h4>
            <button mat-button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              <i class="material-icons">clear</i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group select-number">
                    <label class="bmd-label-floating">From<span
                            class="text-danger">*</span></label>
                    <ng-select bindLabel="name" placeholder="Select Number" appendTo="body"
                        [multiple]="false"[searchable]="true"
                        class="form-control" formControlName="from" [clearable]="true">
                        <ng-option [value]="item.number" *ngFor="let item of numbers" ng-selected="item.is_default == '1'">
                            {{item.number}} ({{item.title}})
                        </ng-option>
                    </ng-select>
                </div>
                <div *ngIf="submitted && f.from.errors">
                  <div class="error-msg" *ngIf="submitted && f.from.errors.required">* From is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">To<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="to">
                </div>
                <div *ngIf="submitted && f.to.errors">
                  <div class="error-msg" *ngIf="submitted && f.to.errors.required">* To is required</div>
                  <div class="error-msg" *ngIf="submitted && f.to.errors.pattern">To number must be at least 10 numbers </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">Message <span class="text-danger">*</span></label>
                  <textarea #input maxlength="600" type="text" class="form-control"
                    formControlName="message"></textarea>
                </div>
                <div *ngIf="submitted && f.message.errors">
                  <div class="error-msg" *ngIf="submitted && f.message.errors.required">* Message is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button [disabled]="progressLoader" mat-raised-button type="button" (click)="resetForm()"
              class="btn cancel-btn pull-right">Reset</button>
            <button *ngIf="!progressLoader" mat-raised-button type="submit"
              class="btn theme-btn pull-right">Send Message</button>
            <button *ngIf="progressLoader" mat-raised-button class="btn theme-btn pull-right"><i
                class="fa fa-circle-o-notch fa-spin"></i></button>
          </div>
        </form>
      </div>
  </div>
</div>
<!--  End open new message Form Modal -->

<!--open new payment  Form Model -->
<div class="modal fade" id="opennewmodalforpayment" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content" >
        <form class="form-horizontal" [formGroup]="PaymentForm" (ngSubmit)="onSubmitPayment()" >
          <div class="modal-header">
            <h4 class="modal-title">Send Payment link</h4>
            <button mat-button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              <i class="material-icons">clear</i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group select-number">
                    <label class="bmd-label-floating">From<span
                            class="text-danger">*</span></label>
                    <ng-select bindLabel="name" placeholder="Select Number" appendTo="body"
                        [multiple]="false"[searchable]="true"
                        class="form-control" formControlName="from" [clearable]="true">
                        <ng-option [value]="item.number" *ngFor="let item of numbers" ng-selected="item.is_default == '1'">
                            {{item.number}} ({{item.title}})
                        </ng-option>
                    </ng-select>
                </div>
                <div *ngIf="submitted && p.from.errors">
                  <div class="error-msg" *ngIf="submitted && p.from.errors.required">* From is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">To<span class="text-danger">*</span></label>
                  <input type="text" placeholder="9876543210" class="form-control" formControlName="to">
                </div>
                <div *ngIf="submitted && p.to.errors">
                  <div class="error-msg" *ngIf="submitted && p.to.errors.required">* To is required</div>
                  <div class="error-msg" *ngIf="submitted && p.to.errors.pattern ">To number must be at least 10 numbers </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">Amount<span class="text-danger">*</span></label>
                  <input  step="0.01" type="number"
                  name="payment"
                  placeholder="3.00" class="form-control" formControlName="amount">
                </div>
                <div *ngIf="submitted && p.amount.errors">
                  <div class="error-msg" *ngIf="submitted && p.amount.errors.required">* Amount is required</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">Invoice Number<span class="text-danger">*</span></label>
                  <input type="text"
                  placeholder="PO/Invoice/Transaction Number" class="form-control" formControlName="invoicenumber">
                </div>
                <div *ngIf="submitted && p.invoicenumber.errors">
                  <div class="error-msg" *ngIf="submitted && p.invoicenumber.errors.required">* Invoice Number is required</div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">Description <span class="text-danger">*</span></label>
                  <textarea #input maxlength="600" type="text" class="form-control"
                    formControlName="description"></textarea>
                </div>
                <div *ngIf="submitted && p.description.errors">
                  <div class="error-msg" *ngIf="submitted && p.description.errors.required">* Description is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button [disabled]="progressLoader" mat-raised-button type="button" (click)="resetForm()"
              class="btn cancel-btn pull-right">Reset</button>
            <button *ngIf="!progressLoader" mat-raised-button type="submit"
              class="btn theme-btn pull-right">Send Payment link</button>
            <button *ngIf="progressLoader" mat-raised-button class="btn theme-btn pull-right"><i
                class="fa fa-circle-o-notch fa-spin"></i></button>
          </div>
        </form>
      </div>
  </div>
</div>
<!--  End open new payment Form Modal -->


<!--open new message  Form Model -->
<div class="modal fade" id="openmultifactorAuthentication" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content" >
        <form class="form-horizontal" [formGroup]="MultifactorAuthenticationForm" (ngSubmit)="onmultifactorAuthenticationSubmit()" >
          <div class="modal-header">
            <h4 class="modal-title">Multi-factor Authentication</h4>
            
            <!-- <button mat-button type="button" class="close" data-dismiss="modal" aria-hidden="true">
              <i class="material-icons">clear</i>
            </button> -->
          </div>
          <div class="modal-body">
            
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="bmd-label-floating">OTP<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="otp">
                  <p style="color: green;">Please check for phone or email for OTP</p>
                </div>
                <div *ngIf="submitted && m.otp.errors">
                  <div class="error-msg" *ngIf="submitted && m.otp.errors.required">* OTP is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button mat-raised-button type="button" (click)="resendOTP()"
              class="btn cancel-btn pull-right">Resend OTP</button>
            <button *ngIf="!progressLoader" mat-raised-button type="submit"
              class="btn theme-btn pull-right">OTP verify</button>
            <button *ngIf="progressLoader" mat-raised-button class="btn theme-btn pull-right"><i
                class="fa fa-circle-o-notch fa-spin"></i></button>
          </div>
        </form>
      </div>
  </div>
</div>
<!--  End open new message Form Modal -->