import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
            },{
                path: 'accounts',
                loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule)
            },{
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
            },{
                path: 'keywords',
                loadChildren: () => import('./keywords/keywords.module').then(m => m.KeywordsModule)
            },{
                path: 'blockkeywords',
                loadChildren: () => import('./blockkeywords/blockkeywords.module').then(m => m.BlockkeywordsModule)
            },{
                path: 'conversation',
                loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationModule)
            },{
                path: 'conversation/:number',
                loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationModule)
            },{
                path: 'conversation/model/:type',
                loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationModule)
            },{
                path: 'facebook/messages',
                loadChildren: () => import('./fecbook-messages/fecbook-messages.module').then(m => m.FecbookMessagesModule)
            },{
                path: 'conversation/send/bulk/message',
                loadChildren: () => import('./send-bulk-message/send-bulk-message.module').then(m => m.SendBulkMessageModule)
            },{
                path: 'group-conversation',
                loadChildren: () => import('./groupconversation/groupconversation.module').then(m => m.GroupconversationModule)
            },{
                path: 'contacts',
                loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
            },{
                path: 'contacts/:type',
                loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
            },{
                path: 'did-numbers',
                loadChildren: () => import('./didnumbers/didnumbers.module').then(m => m.DidnumbersModule)
            },{
                path: 'departments',
                loadChildren: () => import('./departments/departments.module').then(m => m.DepartmentsModule)
            },{
                path: 'did-management',
                loadChildren: () => import('./didadmin/didadmin.module').then(m => m.DidadminModule)
            },{
                path: 'templates',
                loadChildren: () => import('./template/template.module').then(m => m.TemplateModule)
            },{
                path: 'emailtemplates',
                loadChildren: () => import('./emailtemplates/emailtemplates.module').then(m => m.EmailtemplatesModule)
            },{
                path: 'didlocation',
                loadChildren: () => import('./didlocation/didlocation.module').then(m => m.DidlocationModule)
            },{
                path: 'account',
                loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
            },{
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },{
                path: '',
                loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
            }, {
                path: 'autoReplies',
                loadChildren: () => import('./auto-replies/auto-replies.module').then(m => m.AutoRepliesModule)
            },{
                path: 'scheduleMessages',
                loadChildren: () => import('./schedule-messages/schedule-messages.module').then(m => m.ScheduleMessagesModule)
            },{
                path: 'billing-plan',
                loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule)
            },{
                path: 'g-reviews',
                loadChildren: () => import('./g-reviews/g-reviews.module').then(m => m.GReviewsModule)
            },{
                path: 'g-reviews/:selectedReview',
                loadChildren: () => import('./g-reviews/g-reviews.module').then(m => m.GReviewsModule)
            },{
                path: 'virtual-terminal',
                loadChildren: () => import('./virtual-info/virtual-info.module').then(m => m.VirtualInfoModule)
            },{
                path: 'batch-upload',
                loadChildren: () => import('./batch-upload/batch-upload.module').then(m => m.BatchUploadModule)
            },{
                path: 'helpdesk',
                loadChildren: () => import('./helpdesk/helpdesk.module').then(m => m.HelpdeskModule)
            },{
                path: 'billing',
                loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule)
            },{
                path: 'invoicehistory',
                loadChildren: () => import('./invoicehistory/invoicehistory.module').then(m => m.InvoicehistoryModule)
            },{
                path: 'pricing',
                loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule)
            },{
                path: 'payment-settings',
                loadChildren: () => import('./payment-settings/payment-settings.module').then(m => m.PaymentSettingsModule)
            },{
                path: 'contact-us-scripts',
                loadChildren: () => import('./contact-us-scripts/contact-us-scripts.module').then(m => m.ContactUsScriptsModule)
            },{
                path: 'reviews-settings',
                loadChildren: () => import('./reviews-settings/reviews-settings.module').then(m => m.ReviewsSettingsModule)
            },{
                path: 'campaign',
                loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
            },{
                path: 'campaign-template',
                loadChildren: () => import('./campaign-template/campaign-template.module').then(m => m.CampaignTemplateModule)
            },{
                path: 'campaign/:tab',
                loadChildren: () => import('./campaign/campaign.module').then(m => m.CampaignModule)
            },{
                path: 'ivr-details',
                loadChildren: () => import('./ivr-details/ivr-details.module').then(m => m.IvrDetailsModule)
            },{
                path: 'ivr-template',
                loadChildren: () => import('./ivr-template/ivr-template.module').then(m => m.IVRTemplateModule)
            },{
                path: 'menu-setttings',
                loadChildren: () => import('./menu-setttings/menu-setttings.module').then(m => m.MenusetttingsModule)
            },{
                path: 'ams-details',
                loadChildren: () => import('./dms-details/dms-details.module').then(m => m.DmsDetailsModule)
            },{
                path: 'ams-template',
                loadChildren: () => import('./ams-template/ams-template.module').then(m => m.AMSTemplateModule)
            },{
                path: 'user-activity-log',
                loadChildren: () => import('./user-activity-log/user-activity-log.module').then(m => m.UserActivityLogModule)
            }

            
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }]
    }
];
