import { environment } from './../../environments/environment';

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private readonly apiUrl = environment.services.gatewayUrl; 
  private readonly _authToken: string = 'accessToken';

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLoggedIn =localStorage.getItem(this._authToken);
    const isApiUrl = request.url.startsWith(this.apiUrl);

    if (isLoggedIn && isApiUrl) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${isLoggedIn}`
            }
        });
    }

    return next.handle(request);
  }
}
