import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AccountsService } from './services/accounts.service';
import { SharedService } from './services/shared.service';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private _router: Subscription;
  private readonly _userDetails: string = 'user_details';
  userdetails : any ="";
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {

    return this.resetTwoFactorVerification(event);
  }

  constructor( private router: Router, 
    private route:ActivatedRoute,
    private accountService: AccountsService,
    private sharedService: SharedService,
    ) {
    // this.userdetails = JSON.parse(atob(localStorage.getItem(this._userDetails)));
    // if(this.userdetails && this.userdetails == undefined){
    // }
  }

    ngOnInit() {

      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
    }

    resetTwoFactorVerification(event){
      if (localStorage.getItem('mfauserstatus') == '1') {
          event.preventDefault();
          event.returnValue = 'Your data will be lost!';
          
        
        this.userdetails = JSON.parse(atob(localStorage.getItem(this._userDetails)));
      
        if (this.userdetails.role_id == 4) {
          var id  = this.userdetails.user_id;
        } else {
          var id  = this.userdetails.id;
        }
        
        this.accountService
          .resetTwoFactorVerification(id)
          .pipe()
          .subscribe(
            (result: any) => {
              this.sharedService.logout();
            }
          );
        return false;
      }else{
        return true;
      }
    }

    
}
