import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SessionStorageService } from './../services/session-storage.service';
import { SharedService } from '../../services/shared.service';
import { ConversationService } from '../../services/conversation.service';
import { AccountsService } from '../../services/accounts.service';
import { environment } from "../../../environments/environment";
import { MenusetttingsService } from '../../services/menu-setttings.service';
import { Subscription } from 'rxjs/Subscription';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { Validators, FormGroup } from '@angular/forms';

import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: false,
    disabled_collapse_init: 0,
};

declare var $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    roleId: any;
    plan : string;
    activekey: any;
}
//Menu Items
export const MAINROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    roleId : 1,
    plan : 'any',
    activekey: 0,
},{
    path: '/accounts',
    title: 'Account Management',
    roleId : 1,
    plan : 'any',
    activekey: 11,
},{
    path: '/did-management',
    title: 'DID management',
    roleId : 1,
    plan : 'any',
    activekey: 12,
},{
    path: '/billing',
    title: 'Billing',
    roleId : 1,
    plan : 'any',
    activekey: 13,
},{
    path: '/menu-setttings',
    title: 'Menu Setttings',
    roleId : 1,
    plan : 'any',
    activekey: 14,
},{
    path: '/helpdesk',
    title: 'Helpdesk',
    roleId : 1,
    plan : 'any',
    activekey: 5,
},{
    path: '/dashboard',
    title: 'Dashboard',
    roleId : 2,
    plan : 'any',
    activekey: 0,
},{
    path: '/conversation',
    title: 'Message',
    roleId : 2,
    plan : 'any',
    activekey: 1,
},{
    path: '/contacts/single',
    title: 'Contacts',
    roleId : 2,
    plan : 'any',
    activekey: 2,
},{
    path: '/virtual-terminal',
    title: 'Payments',
    roleId : 2,
    plan : 'any',
    activekey: 3,
},{
    path: '/g-reviews/google',
    title: 'Reviews',
    roleId : 2,
    plan : 'any',
    activekey: 4,
},{
    path: '/autoReplies',
    title: 'Features',
    roleId : 2,
    plan : 'any',
    activekey: 6,
},{
    path: '/campaign',
    title: 'Campaign',
    roleId : 2,
    plan : 'any',
    activekey: 8,
},{
    path: '/ivr-details',
    title: 'IVR',
    roleId : 2,
    plan : 'any',
    activekey: 9,
},{
    path: '/helpdesk',
    title: 'Help',
    roleId : 2,
    plan : 'any',
    activekey: 5,
},{
    path: '/dashboard',
    title: 'Dashboard',
    roleId : 3,
    plan : 'any',
    activekey: 0,
},{
    path: '/conversation',
    title: 'Message',
    roleId : 3,
    plan : 'any',
    activekey: 1,
},{
    path: '/contacts/single',
    title: 'Contacts',
    roleId : 3,
    plan : 'any',
    activekey: 2,
},{
    path: '/virtual-terminal',
    title: 'Payments',
    roleId : 3,
    plan : 'any',
    activekey: 3,
},{
    path: '/g-reviews/google',
    title: 'Reviews',
    roleId : 3,
    plan : 'any',
    activekey: 4,
},{
    path: '/dashboard',
    title: 'Dashboard',
    roleId : 4,
    plan : 'any',
    activekey: 0,
},{
    path: '/conversation',
    title: 'Message',
    roleId : 4,
    plan : 'any',
    activekey: 1,
},{
    path: '/contacts/single',
    title: 'Contacts',
    roleId : 4,
    plan : 'any',
    activekey: 2,
},{
    path: '/virtual-terminal',
    title: 'Payments',
    roleId : 4,
    plan : 'any',
    activekey: 3,
},{
    path: '/g-reviews/negative',
    title: 'Reviews',
    roleId : 4,
    plan : 'any',
    activekey: 4,
}]
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    Selectedmenu: any[];
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    private readonly _userDetails: string = 'user_details';
    userdetails: any = ""
    intervalForUnreadCount: any = "";
    intervalForGoogleAccessCode : any = "";
    newRevieewCount : any  = 0;
    progressLoader: boolean = false;
    unreadcon : any = 0;
    negaiveReviewCount : any = 0;
    dropdown_show: boolean = false;
    dropdown_show_new: boolean = false;
    activemenu : any = 0;
    mySubscription: any;
    userRole:any;
    MessageForm: FormGroup;
    PaymentForm: FormGroup;
    MultifactorAuthenticationForm: FormGroup;
    numbers: any = [];
    selectedfrom: any = "";
    submitted: boolean = false;
    convenienceFee: boolean = true;
    paymenttype = 1;
    public menu_Items: any[];


    @ViewChild('app-navbar-cmp') button: any;

    constructor(
        private toastr: ToastrService,
        location: Location,
        private renderer: Renderer2,
        private element: ElementRef,
        private router: Router,
        private sessionStorageService: SessionStorageService,
        public sharedService: SharedService,
        public conversationService: ConversationService,
        private accountService: AccountsService,
        private formBuilder: FormBuilder,
        private menusetttingsService: MenusetttingsService,
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;

        this.userdetails = JSON.parse(atob(localStorage.getItem(this._userDetails)));
        if (this.userdetails && this.userdetails == undefined) {
            this.logout();
        }
        this.userRole =  this.userdetails.role_id;
        //console.log('check router;-'+ this.router.url ); //  /routename
        this.setactivemenubyurl(this.router.url);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
          this.mySubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              // Here is the dashing line comes in the picture.
              // You need to tell the router that, you didn't visit or load the page previously, so mark the navigated flag to false as below.
              this.router.navigated = false;
            }
          });

    }
    minimizeSidebar() {
        const body = document.getElementsByTagName('body')[0];

        if (misc.sidebar_mini_active === true) {
            body.classList.remove('sidebar-mini');
            misc.sidebar_mini_active = false;

        } else {
            setTimeout(function () {
                body.classList.add('sidebar-mini');

                misc.sidebar_mini_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }
    hideSidebar() {
        const body = document.getElementsByTagName('body')[0];
        const sidebar = document.getElementsByClassName('sidebar')[0];

        if (misc.hide_sidebar_active === true) {
            setTimeout(function () {
                body.classList.remove('hide-sidebar');
                misc.hide_sidebar_active = false;
            }, 300);
            setTimeout(function () {
                sidebar.classList.remove('animation');
            }, 600);
            sidebar.classList.add('animation');

        } else {
            setTimeout(function () {
                body.classList.add('hide-sidebar');
                // $('.sidebar').addClass('animation');
                misc.hide_sidebar_active = true;
            }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        const simulateWindowResize = setInterval(function () {
            window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function () {
            clearInterval(simulateWindowResize);
        }, 1000);
    }

    async ngOnInit() {
        

        if (this.intervalForUnreadCount) {
            clearInterval(this.intervalForUnreadCount);
        }
        if (this.intervalForGoogleAccessCode) {
            clearInterval(this.intervalForGoogleAccessCode);
        }


        this.listTitles = ROUTES.filter(listTitle => listTitle);
        this.menu_Items = MAINROUTES.filter(menu_Items => menu_Items);
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            this.sidebarClose();

            const $layer = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
            }
        });

        this.MessageForm = this.formBuilder.group({
            from: ["", [Validators.required]],
            to: ["", [Validators.required, ,
                Validators.pattern("^[0-9]*$"),
                Validators.minLength(10), Validators.maxLength(10)]],
            message: ["", [Validators.required]],
          });

          this.MultifactorAuthenticationForm = this.formBuilder.group({
            otp: ["", [Validators.required, ,
                Validators.pattern("^[0-9]*$")]]
          });
        

        this.PaymentForm = this.formBuilder.group({
            from: ["", [Validators.required]],
            to: ["", [Validators.required, ,
                Validators.pattern("^[0-9]*$"),
                Validators.minLength(10), Validators.maxLength(10)]],
            amount: ["", [Validators.required]],
            invoicenumber: ["", [Validators.required]],
            description: ["", [Validators.required]],
          });

          

        // this.getUnreadCount();

        this.intervalForUnreadCount = setInterval(() => {
            this.getUnreadCount();
        }, 60000);

        // this.intervalForGoogleAccessCode = setInterval(() => {
        //     this.generateAccessCode();
        // }, 1500000);
        if (this.userdetails.role_id <= 2) {
          await this.getUserMenusetttings(this.userdetails.id);
        } else {
          await this.getUserMenusetttings(this.userdetails.user_id);
        }
    }
    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }


    get f() { return this.MessageForm.controls; };

    get p() { return this.PaymentForm.controls; };
    get m() { return this.MultifactorAuthenticationForm.controls }

    ngOnDestroy() {
        if (this.mySubscription) {
          this.mySubscription.unsubscribe();
        }
      }

    sidebarOpen() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function () {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        } else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function () {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function () { //asign a function
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            this.sidebarVisible = false;

            $layer.classList.remove('visible');
            setTimeout(function () {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    };
    sidebarClose() {
        var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        this.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle() {
        let titlee: any = this.location.prepareExternalUrl(this.location.path());
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }

    opentelephone(){
        window.open('https://cloud11.netone.io/webapp/#/login');
    }

    onSubmit(){
        this.submitted = true;
        // stop here if form is invalid
        if (this.MessageForm.invalid) {
            return;
        }
        const formData = new FormData();
        var messageOwner: any = "";
        if (this.userdetails.role_id == 2) {
            messageOwner = this.userdetails.id;
        } else {
            messageOwner = this.userdetails.user_id;
        }

        formData.append('sendFrom', this.MessageForm.value.from);
        formData.append('sendTo', this.MessageForm.value.to);
        formData.append('is_new', '0');
    

        formData.append('file', '');
        formData.append('user_id', messageOwner);
        this.progressLoader = true;

       
        if (this.userdetails.is_signature_active =='1') {
        formData.append('message', this.MessageForm.value.message + '\n\n' + this.userdetails.signature);
        } else {
        formData.append('message', this.MessageForm.value.message);
        }

        this.conversationService.sendMessage(formData).pipe().subscribe((result) => {
            this.toastr.success(result['message']);
            this.progressLoader = false;
            this.submitted = false;
            $('#opennewmodal').modal('hide');
            this.MessageForm.reset();
        }, (error: any) => {
                this.progressLoader = false;
                if (error.error.message != null && error.error.message != "") {
                    this.toastr.error(error.error.message);
                } else {
                    var key = Object.keys(error.error)[0];
                    this.toastr.error(
                    error.error[key][0]
                    );
                }
                if (error.status == 403) {
                    this.sharedService.logout();
                }
            }
        );
    }

    onSubmitPayment(){
        this.submitted = true;
        // stop here if form is invalid
        if (this.PaymentForm.invalid) {
            return;
        }
        this.togglePayment();

        var parentUser = this.userdetails.id;
        const formData = new FormData();
        formData.append('amount', parseFloat(this.PaymentForm.value.amount).toFixed(2));
        formData.append('description', this.PaymentForm.value.description);
        formData.append('message', '');
        formData.append('invoiceNumber', this.PaymentForm.value.invoicenumber);
        formData.append('type', this.paymenttype == 1 ? '1':'2');
        formData.append('convenienceFee', this.convenienceFee ? '1' : '0');
        formData.append('username', this.userdetails.username);
        formData.append('mobile', this.PaymentForm.value.to);
        formData.append('is_new', '0');
    
        if(this.userdetails.paymentmode =='authvia'){
        this.conversationService
          .createPaymentLink(parentUser, formData)
          .pipe()
          .subscribe(
            (result: any) => {
              this.sendMessageWithoutSendToPhone(parseFloat(result.data.context['amount']).toFixed(2), result.data.context['description'],
               result.data.id, JSON.stringify(result.data), this.convenienceFee, this.PaymentForm.value.invoicenumber);
              this.toastr.success("Payment link successfully send to customer.");
              $('#opennewmodalforpayment').modal('hide');
                this.PaymentForm.reset();
            },
            (error: any) => {
             
    
              if (error.status == 401) {
                return false;
              }
              if (error.error.message != null && error.error.message != "") {
                this.toastr.error(error.error.message);
              } else {
                this.toastr.error(error.error.detail);
              }
              if (error.status == 403) {
                this.sharedService.logout();
              }
            }
          );
        }else{
          this.conversationService
          .createauxvaultPaymentLink(parentUser, formData)
          .pipe()
          .subscribe(
            (result: any) => {
             
              this.sendMessageSendToPhone((parseFloat(this.PaymentForm.value.amount).toFixed(2)), this.PaymentForm.value.description, result.data.data.id, JSON.stringify(result.data), 
              this.convenienceFee,  this.PaymentForm.value.invoiceNumber);
              this.toastr.success("Payment link successfully send to customer.");
              $('#opennewmodalforpayment').modal('hide');
                this.PaymentForm.reset();
            },
            (error: any) => {
    
              if (error.status == 401) {
                return false;
              }
              if (error.error.message != null && error.error.message != "") {
                this.toastr.error(error.error.message);
              } else {
                this.toastr.error(error.error.detail);
              }
              if (error.status == 403) {
                this.sharedService.logout();
              }
            }
          );
        }
      }
    
    
      sendMessageWithoutSendToPhone(amount, description, messageId, response, convenienceFee, ref) {
    
        const formData = new FormData();
        let messageOwner: any = "";
        if (this.userdetails.role_id == 2) {
          messageOwner = this.userdetails.id;
        } else {
          messageOwner = this.userdetails.user_id;
        }

          formData.append('sendFrom', this.PaymentForm.value.from);
          formData.append('sendTo', this.PaymentForm.value.to);
          formData.append('is_new', '0');
    
        if (this.userdetails.is_signature_active == 1) {
          if (convenienceFee != "") {
            formData.append('message', "<i class='fa fa-usd'></i>You payment request link has been sent for $" + amount + " + $" + convenienceFee/100 + " Convenience fee. Once it has been paid you will receive a text response.<br>Description : " + description + "" + '<br><br>' + this.userdetails.signature);
          } else {
            formData.append('message', "<i class='fa fa-usd'></i>You payment request link has been sent for $" + amount + ". Once it has been paid you will receive a text response. <br>Description : " + description + "" + '<br><br>' + this.userdetails.signature);
          }
        } else {
          if (convenienceFee != "") {
            formData.append('message', "<i class='fa fa-usd'></i> You payment request link has been sent for $" + amount + " +  $" + convenienceFee/100 + " Convenience fee. Once it has been paid you will receive a text response. ");
          } else {
            formData.append('message', "<i class='fa fa-usd'></i> You payment request link has been sent for $" + amount + ". Once it has been paid you will receive a text response. <br>Description : " + description + "");
          }
    
        }
        if (convenienceFee != "") {
          formData.append('conveniencefee', convenienceFee);
        }
        
        formData.append('file', "");
        formData.append('message_id', messageId);
        formData.append('user_id', messageOwner);
        formData.append('response', response);
        formData.append('ref', ref);
        formData.append('amount', amount);
    
    
        this.progressLoader = true;
        this.conversationService.sendMessageWithoutBandwidth(formData).pipe().subscribe((result) => {
          this.progressLoader = false;
        }, (error: any) => {
          if (error.error.message != null && error.error.message != "") {
            this.toastr.error(error.error.message);
          } else {
            var key = Object.keys(error.error)[0];
            this.toastr.error(
              error.error[key][0]
            );
          }
          if (error.status == 403) {
            this.sharedService.logout();
          }
        }
        );
    
      }
    
      sendMessageSendToPhone(amount, description, messageId, response, convenienceFee, ref) {
    
        const formData = new FormData();
        var messageOwner: any = "";
        if (this.userdetails.role_id == 2) {
          messageOwner = this.userdetails.id;
        } else {
          messageOwner = this.userdetails.user_id;
        }
        formData.append('sendFrom', this.PaymentForm.value.from);
        formData.append('sendTo', this.PaymentForm.value.to);
        formData.append('is_new', '0');
    
        formData.append('smsstatus', '1');
        if(this.paymenttype == 1){
          var type = 'payment';
        }else{
          var type = 'Authorize Only';
        }
    
        if (this.userdetails.is_signature_active == 1) {
          if (convenienceFee == "0") {
            formData.append('message', "<i class='fa fa-usd'></i>Your "+type+" request link has been sent for $" + amount + " + Conv. Fee.  Once your customer has completed the payment request, you will receive a confirmation text message with the approval number. <br>Description : " + description + "" + '<br><br>' + this.userdetails.signature);
          } else {
            formData.append('message', "<i class='fa fa-usd'></i>Your "+type+" request link has been sent for $" + amount + ".  Once your customer has completed the payment request, you will receive a confirmation text message with the approval number. <br>Description : " + description + "" + '<br><br>' + this.userdetails.signature);
          }
        } else {
          if (convenienceFee == "0") {
            formData.append('message', "<i class='fa fa-usd'></i> Your "+type+" request link has been sent for $" + amount + " + Conv. Fee.  Once your customer has completed the payment request, you will receive a confirmation text message with the approval number.");
          } else {
            formData.append('message', "<i class='fa fa-usd'></i> Your "+type+" request link has been sent for $" + amount + ".  Once your customer has completed the payment request, you will receive a confirmation text message with the approval number. <br>Description : " + description + "");
          }
    
        }
        if (convenienceFee != "") {
          formData.append('conveniencefee', convenienceFee);
        }
        formData.append('type', this.paymenttype == 1 ? '1':'2');
        formData.append('username', this.userdetails.username);
        formData.append('file', "");
        formData.append('message_id', messageId);
        formData.append('user_id', messageOwner);
        formData.append('response', response);
        formData.append('ref', ref);
        formData.append('amount', amount);
    
    
        this.progressLoader = true;
        this.conversationService.sendMessagewithBandwidth(formData).pipe().subscribe((result) => {
          this.progressLoader = false;
    
        }, (error: any) => {
          if (error.error.message != null && error.error.message != "") {
            this.toastr.error(error.error.message);
          } else {
            var key = Object.keys(error.error)[0];
            this.toastr.error(
              error.error[key][0]
            );
          }
          if (error.status == 403) {
            this.sharedService.logout();
          }
        }
        );
    
      }

    togglePayment() {

          this.accountService.gatewaydetail(this.userdetails.id).pipe().subscribe((result) => {
            this.submitted = false;
            if (result.type != "error") {
              if (result['data'][0].status == '1') {
                this.convenienceFee = true;
              } else {
                this.convenienceFee = false;
              }
              this.paymenttype = result['data'][0].payment_type;
            }
            this.progressLoader = false;
          }, (error: any) => {
            this.progressLoader = false;
            if (error.status == 403) {
              this.sharedService.logout();
            }
          }
          );
      }

    resetForm(){
        this.MessageForm.reset();
        this.PaymentForm.reset();
    }

    logout() {

        if (this.intervalForUnreadCount) {
            clearInterval(this.intervalForUnreadCount);
        }
        if (this.intervalForGoogleAccessCode) {
            clearInterval(this.intervalForGoogleAccessCode);
        }
        this.sharedService.logout();
    }

    playAudio() {
        let audio = new Audio();
        audio.src = environment.services.gatewayUrl+"notification_sound/incomingmsg.mp3";
        audio.volume = this.userdetails.alert_volume / 10;
        audio.load();
        audio.play();
      }

    getUnreadCount() {
        var loginUserId: any = "";
        if (this.userdetails.role_id == 4) {
            loginUserId = this.userdetails.user_id;
        } else {
            loginUserId = this.userdetails.id;
        }

        
        const formData = new FormData();
        formData.append('page', "1");
        formData.append('limit', "50");
        formData.append('search', "");
        formData.append('role_id', this.userdetails.role_id);
        formData.append('onlyunreadcount', "1");
        if (this.userdetails.role_id == '2') {
            formData.append('loggedInUser', this.userdetails.id);
        } else {
            formData.append('loggedInUser', this.userdetails.user_id);
        }

        if (this.router.url == "/conversation" || loginUserId == 1) {
            this.conversationService.getNegativeReviewCount(loginUserId, formData)
            .pipe()
            .subscribe(
                (result: any) => {
                    this.sharedService.settotalNegativeReview(result.negaiveReviewCount);
                    if (this.negaiveReviewCount != result.negaiveReviewCount) {
                      console.log('3');
                        this.playAudio();
                    }
                    this.negaiveReviewCount = result.negaiveReviewCount;

                    return false;
                },
                (error: any) => {
                    if (error.status == 403) {
                        this.logout();
                    }
                    return false;

                }
            );
        }else{
            this.conversationService.getUnreadCount(loginUserId, formData)
            .pipe()
            .subscribe(
                (result: any) => {
                    this.sharedService.settotalUnreadConversation(result.unreadcon);
                    this.sharedService.settotalNegativeReview(result.negaiveReviewCount);
                    // console.log(this.unreadcon, this.negaiveReviewCount)
                    if (result.unreadcon != this.unreadcon || result.negaiveReviewCount != this.negaiveReviewCount) {
                      console.log('4');
                      // this.playAudio();
                    }
                    
                    this.unreadcon = result.unreadcon;
                    this.negaiveReviewCount = result.negaiveReviewCount;
                    // console.log(this.unreadcon, this.negaiveReviewCount)
                },
                (error: any) => {
                    if (error.status == 401) {
                        return false;
                    }
                    if (error.status == 403) {
                        this.logout();
                    }
                }
            );
        }

        
    }

    ngAfterViewInit() {
        // this.generateAccessCode();
    }

    clickMenu(val){
        //console.log(val);
        this.sharedService.setactivemenu(val);
        this.activemenu = val;
    }

    checkmenu(){
        this.activemenu = this.sharedService.getactivemenu();
    }


    dropdownshow(){
        this.dropdown_show = !this.dropdown_show;
        //onsole.log('working')
        if(this.dropdown_show){
            setTimeout(() => {
                console.log('sleep');
                $('#navbarDropdownpopup').addClass("show");
                $('.dropdown').addClass("show");
                
                // And any other code that should run only after 5s
              }, 400);
        }
        
    }

    dropdownshownew(){
        this.dropdown_show_new = !this.dropdown_show_new;
        if(this.dropdown_show_new){
            setTimeout(() => {
                console.log('sleep');
                $('#navbarDropdownpopupnew').addClass("show");
                $('.dropdown').addClass("show");
                // And any other code that should run only after 5s
              }, 400);
        }
        
    }


    generateAccessCode() {
        var loginUserId: any = "";
        loginUserId = this.userdetails.id;
        this.accountService.getGoogleReviewSettingByUser(loginUserId).pipe().subscribe((result) => {
            if(result['data'].client_id && result['data'].client_id != "" && result['data'].client_id != null && result['data'].client_secret != ""){
                let data = {
                    "client_id" : result['data'].client_id,
                    "client_secret" : result['data'].client_secret,
                    "refresh_token" : result['data'].review_refresh_token,
                    "grant_type" : "refresh_token",
                }
                var accountID = result['data'].review_account_id;
                var locationID = result['data'].review_location_id;
                var currentReviews = result['data'].total_reviews;

                this.accountService.getAcceessTokenUsingRefresh(data).pipe().subscribe((tokenData) => {
                    console.log(tokenData);
                    this.sharedService.setGoogleAccessToken(tokenData['access_token']);                    
                        this.conversationService
                          .getGoogleReview(accountID, locationID, tokenData['access_token'])
                          .pipe()
                          .subscribe(
                            (data: any) => {
                    
                              if (Object.keys(result).length > 0) {
                                this.newRevieewCount = data.totalReviewCount - currentReviews;
                              }
                            },
                            (error: any) => {
                              if (error.status == 403) {
                                this.sharedService.logout();
                              }
                            }
                          );
                    
                    
                    
                }, (error: any) => {
                    if (error.status == 403) {
                        this.sharedService.logout();
                    }
                }
                );
            }
            
        }, (error: any) => {
            if (error.status == 403) {
                this.sharedService.logout();
            }
        }
        );
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    onmultifactorAuthenticationSubmit(){

      this.submitted = true;
      // stop here if form is invalid
      if (this.MultifactorAuthenticationForm.invalid) {
          return;
      }
      const formData = new FormData();      
      formData.append('otp', this.MultifactorAuthenticationForm.value.otp);
      formData.append('uniquebrowserId', localStorage.getItem('UUID'));
      if (this.userdetails.role_id == 2) {
        var id = this.userdetails.id;
      } else {
        var id = this.userdetails.user_id;
      }
      this.menusetttingsService
        .userOTPverify(id, formData)
        .pipe()
        .subscribe(
          (result: any) => {
            this.submitted = false;
            this.toastr.success(result['message']);
            $('#openmultifactorAuthentication').modal('hide');
            localStorage.setItem('nextMFAdate', result['nextdate']);
            $('.main-content').removeClass('overlay');
          },
          (error: any) => {
  
            if (error.error.message != null && error.error.message != "") {
              this.toastr.error(error.error.message);
            } else {
              this.toastr.error(error.error.detail);
            }
            if (error.status == 403) {
              this.sharedService.logout();
            }
          }
        );
    }
    resendOTP(){
      if (this.userdetails.role_id == 2) {
        var id = this.userdetails.id;
      } else {
        var id = this.userdetails.user_id;
      }
      this.menusetttingsService
        .userResendOTP(id)
        .pipe()
        .subscribe(
          (result: any) => {
            this.toastr.success(result['message']);
          },
          (error: any) => {
  
            if (error.error.message != null && error.error.message != "") {
              this.toastr.error(error.error.message);
            } else {
              this.toastr.error(error.error.detail);
            }
            if (error.status == 403) {
              this.sharedService.logout();
            }
          }
        );
    
    }


    getAccounts() {

        var loginUser: any = "";
        if (this.userdetails.role_id == 4) {
          loginUser = this.userdetails.user_id;
        } else {
          loginUser = this.userdetails.id;
        }
        this.conversationService
          .getAccounts(loginUser, this.userdetails.role_id)
          .pipe()
          .subscribe(
            (result: any) => {
                this.numbers = result.data;
                if (result.data.length == 1) {
                  this.MessageForm.controls.from.setValue(this.numbers[0].number);
                  this.PaymentForm.controls.from.setValue(this.numbers[0].number);
                  this.onSubmit();
                } else {
                  if (this.userdetails.role_id == 4) {
                    var is_defaultnumber = this.numbers.find(x => x.number == this.userdetails.defaultdid);
                    if(is_defaultnumber){
                      this.MessageForm.controls.from.setValue(is_defaultnumber.number);
                      this.PaymentForm.controls.from.setValue(is_defaultnumber.number);
                    }
                  } else {
                    var is_defaultnumber = this.numbers.find(x => x.is_default == '1');
                    if(is_defaultnumber){
                      this.MessageForm.controls.from.setValue(is_defaultnumber.number);
                      this.PaymentForm.controls.from.setValue(is_defaultnumber.number);
                    }
                  }
                 
                }
            },
            (error: any) => {
    
              if (error.error.message != null && error.error.message != "") {
                this.toastr.error(error.error.message);
              } else {
                this.toastr.error(error.error.detail);
              }
              if (error.status == 403) {
                this.sharedService.logout();
              }
            }
          );
    
      }

    isActive(menu){
      if(menu.title == 'Campaign'){
        if(this.userdetails.campaignAccess == '1'){
          return true;
        }else{
          return false;
        }
      }else if(menu.title == 'IVR'){
        if(this.userdetails.ivrAccess == '1'){
          return true;
        }else{
          return false;
        }
      }else{
        return true;
      }

    }


    setactivemenubyurl(url){

        switch (url) {
            case '/dashboard':
                this.sharedService.setactivemenu(1);
                localStorage.setItem('activemenu', '1');
                this.activemenu = 1;
                break;

            case '/conversation':
                this.sharedService.setactivemenu(7);
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;

            case '/conversation/**':
                this.sharedService.setactivemenu(7);
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;
                
            case '/group-conversation':
                this.sharedService.setactivemenu(7);
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;

            case '/conversation/model/new':
                this.sharedService.setactivemenu(7);
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;
                
            case '/conversation/send/bulk/message':
                this.sharedService.setactivemenu(7);
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;
            
            case '/facebook/messages':
                this.sharedService.setactivemenu(7);
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;
            
            case '/scheduleMessages':
                this.sharedService.setactivemenu('7');
                localStorage.setItem('activemenu', '7');
                this.activemenu = 7;
                break;

            case '/contacts/single':
                this.sharedService.setactivemenu(8);
                localStorage.setItem('activemenu', '8');
                this.activemenu = 8;
                break;
            
            case '/contacts/groups':
                this.sharedService.setactivemenu(8);
                localStorage.setItem('activemenu', '8');
                this.activemenu = 8;
                break;
            
            case '/virtual-terminal':
                this.sharedService.setactivemenu('9');
                localStorage.setItem('activemenu', '9');
                this.activemenu = 9;
                break;

            case '/batch-upload':
                this.sharedService.setactivemenu('9');
                localStorage.setItem('activemenu', '9');
                this.activemenu = 9;
                break;

            case '/g-reviews/google':
                this.sharedService.setactivemenu('10');
                localStorage.setItem('activemenu', '10');
                this.activemenu = 10;
                break;
            
            case '/g-reviews/yelp':
                this.sharedService.setactivemenu('10');
                localStorage.setItem('activemenu', '10');
                this.activemenu = 10;
                break;

            case '/g-reviews/negative':
                this.sharedService.setactivemenu('10');
                localStorage.setItem('activemenu', '10');
                this.activemenu = 10;
                break;

            case '/helpdesk':
                this.sharedService.setactivemenu('6');
                localStorage.setItem('activemenu', '6');
                this.activemenu = 6;
                break;
            
            case '/autoReplies':
                this.sharedService.setactivemenu('11');
                localStorage.setItem('activemenu', '11');
                this.activemenu = 11;
                break;

            case '/keywords':
                this.sharedService.setactivemenu('11');
                localStorage.setItem('activemenu', '11');
                this.activemenu = 11;
                break;

            case '/blockkeywords':
                this.sharedService.setactivemenu('11');
                localStorage.setItem('activemenu', '11');
                this.activemenu = 11;
                break;

            case '/templates':
                this.sharedService.setactivemenu('11');
                localStorage.setItem('activemenu', '11');
                this.activemenu = 11;
                break;
            
            case '/settings':
                this.sharedService.setactivemenu('37');
                localStorage.setItem('activemenu', '37');
                this.activemenu = 37;
                break;

            case '/contact-us-scripts':
                this.sharedService.setactivemenu('37');
                localStorage.setItem('activemenu', '37');
                this.activemenu = 37;
                break;

            case '/payment-settings':
                this.sharedService.setactivemenu('37');
                localStorage.setItem('activemenu', '37');
                this.activemenu = 37;
                break;
            
            case '/reviews-settings':
                this.sharedService.setactivemenu('37');
                localStorage.setItem('activemenu', '37');
                this.activemenu = 37;
                break;
            
            case '/accounts':
                this.sharedService.setactivemenu('2');
                localStorage.setItem('activemenu', '2');
                this.activemenu = 2;
                break;

            case '/did-management':
                this.sharedService.setactivemenu('3');
                localStorage.setItem('activemenu', '3');
                this.activemenu = 3;
                break;
            
            case '/billing':
                this.sharedService.setactivemenu('4');
                localStorage.setItem('activemenu', '4');
                this.activemenu = 4;
                break;

            case '/campaign':
                this.sharedService.setactivemenu('12');
                localStorage.setItem('activemenu', '12');
                this.activemenu = 12;
                break;

            case '/campaign/report':
                this.sharedService.setactivemenu('12');
                localStorage.setItem('activemenu', '12');
                this.activemenu = 12;
                break;

            case '/campaign/sendmessage':
                this.sharedService.setactivemenu('12');
                localStorage.setItem('activemenu', '12');
                this.activemenu = 12;
                break;
              
            case '/campaign-template':
                this.sharedService.setactivemenu('12');
                localStorage.setItem('activemenu', '12');
                this.activemenu = 12;
                break;

            case '/ivr-details':
                this.sharedService.setactivemenu('13');
                localStorage.setItem('activemenu', '13');
                this.activemenu = 13;
                break;
                
            case '/ivr-template':
                this.sharedService.setactivemenu('13');
                localStorage.setItem('activemenu', '13');
                this.activemenu = 13;
                break;

            case '/menu-setttings':
                  this.sharedService.setactivemenu('5');
                  localStorage.setItem('activemenu', '5');
                  this.activemenu = 5;
                  break;
            case '/ams-template':
                  this.sharedService.setactivemenu('44');
                  localStorage.setItem('activemenu', '44');
                  this.activemenu = 44;
                  break;

            case '/ams-details':
                  this.sharedService.setactivemenu('44');
                  localStorage.setItem('activemenu', '44');
                  this.activemenu = 44;
                  break;
            case '/user-activity-log':
              if(this.userdetails.role_id == '1'){
                this.sharedService.setactivemenu('47');
                localStorage.setItem('activemenu', '47');
                this.activemenu = 47;
              }else{
                this.sharedService.setactivemenu('37');
                localStorage.setItem('activemenu', '37');
                this.activemenu = 37;
              }
              break;

                  
                      
            default:
                break;
        }
    }

    async getUserMenusetttings(id) {
      const formData = new FormData();      
      formData.append('nextMFAdate', localStorage.getItem('nextMFAdate'));
      formData.append('uniquebrowserId', localStorage.getItem('UUID'));
      this.menusetttingsService
        .getUserMenusetttingWithMFA(formData, id)
        .pipe()
        .subscribe(
          (result: any) => {
            this.Selectedmenu = result.data.menulist;
            if(this.userdetails.multifactorauthentication == '1'){
              // console.log(result.data.MultFactorAuthentication.status);
              if(result.data.MultFactorAuthentication == true){
                // localStorage.setItem('nextMFAdate', result.data.MultFactorAuthentication.created_at);
                // $('#openmultifactorAuthentication').modal('show');
                // $('.main-content').addClass('overlay');
                this.router.navigate(['/mfa']);
                
              }
            }
            localStorage.setItem('Selectedmenu', JSON.stringify(this.Selectedmenu));
          }
        );
    }

}
