import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpService } from "../shared/services/http.service";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MenusetttingsService {

  private readonly apiMenusetttingsBaseUrl =
    environment.services.gatewayUrl + environment.services.menusettting.baseUrl;

  constructor( private httpService: HttpService) { }

  public listMenusetttings(page, data): Observable<Response> {
    const url = environment.services.menusettting.api.getMenusetttings+"?page="+page;
    return this.httpService.post<Response>(this.apiMenusetttingsBaseUrl, url, data);
  }


  public createMenusettting(data): Observable<Response> {
    const url = environment.services.menusettting.api.postMenusettting;
    return this.httpService.post<Response>(this.apiMenusetttingsBaseUrl, url, data);
  }


  public updateMenusettting(id,data): Observable<Response> {
    const url = environment.services.menusettting.api.updateMenusettting +id;
    return this.httpService.put<Response>(this.apiMenusetttingsBaseUrl, url,data);
  }

  public deleteMenusettting(id): Observable<Response> {
    const url = environment.services.menusettting.api.deleteMenusettting +id;
    return this.httpService.delete<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public getMenusettting(id): Observable<Response> {
    const url = environment.services.menusettting.api.getMenusettting +id;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public getUserMenusettting(id): Observable<Response> {
    const url = environment.services.menusettting.api.getUserMenusettting +id;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public getUserMenusetttingWithMFA(data, id): Observable<Response> {
    const url = environment.services.menusettting.api.getUserMenusetttingWithMFA +id;
    return this.httpService.post<Response>(this.apiMenusetttingsBaseUrl, url, data);
  }

  

  getUserMenusetttingformobile(id): Observable<Response> {
    const url = environment.services.menusettting.api.getUserMenusetttingformobile +id;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public getparentMenusetttings(): Observable<Response> {
    const url = environment.services.menusettting.api.getParentMenusettting;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public getAllMenusettting(): Observable<Response> {
    const url = environment.services.menusettting.api.getAllMenusettting;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public getUserparentMenusettting(id): Observable<Response> {
    const url = environment.services.menusettting.api.getUserparentMenusettting+id;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }
  

  public updateUserMenuSettting(data): Observable<Response> {
    const url = environment.services.menusettting.api.updateUserMenuSettting;
    return this.httpService.post<Response>(this.apiMenusetttingsBaseUrl, url, data);
  }


  public userResendOTP(id): Observable<Response> {
    const url = environment.services.menusettting.api.userresendotp+id;
    return this.httpService.get<Response>(this.apiMenusetttingsBaseUrl, url);
  }

  public userOTPverify(id, data): Observable<Response> {
    const url = environment.services.menusettting.api.userotpverify+id;
    return this.httpService.post<Response>(this.apiMenusetttingsBaseUrl, url, data);
  }
  
  public userSendOTP(id, data): Observable<Response> {
    const url = environment.services.menusettting.api.usersendotp+id;
    return this.httpService.post<Response>(this.apiMenusetttingsBaseUrl, url, data);
  }

}
