// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // apiKey: 'AIzaSyBhPanAqrsmOpLguo_sQx9zGO2GNuFj_eU',
    // authDomain: 'nettext-cff24.firebaseapp.com',
    // projectId: 'nettext-cff24',
    // storageBucket: 'nettext-cff24.appspot.com',
    // messagingSenderId: '436073790386',
    // appId: '1:436073790386:web:1090110d7606e1ff6e02ab'

    apiKey: "AIzaSyCkMm5drtzsCWhIgxGgSLeUJMgZy34qMx4",
    authDomain: "mysms-58739.firebaseapp.com",
    projectId: "mysms-58739",
    storageBucket: "mysms-58739.appspot.com",
    messagingSenderId: "786062014917",
    appId: "1:786062014917:web:3bf649b99884253bf1b3ac",
    ClientID:"418335825649-didq33j1b5ca6t779cnui0tfp7kb0eld.apps.googleusercontent.com", // Google business merchant A/C ClientID 
    FacebookAppID:"1334725087017716"
  },
  appURL : "https://login.auxchat.com",
  services: {
    gatewayUrl:
      // "https://login.auxchat.com/netweb/public/",
      // "http://68.183.144.137/netweb/public/",
      // "https://api.auxchat.com/",
      // "https://dev-api.auxchat.com/",
      // "http://159.65.240.151/netweb/public/",
      "https://api.auxchat.com/",
    auth: {
      baseUrl: "",
      api: {
        postLogin: "login",
        postForgotPassword: "forgot-password",
        isUrlValid: "reset-password/",
        postConfirmResetPassword: "password_reset/confirm/",
        postUser: "create-user",
      },
    },
    dashboadrd:{
      baseUrl: "api/",
      api:{
        dashboardData: "user/dashboard/",
      }
    },
    user:{
      baseUrl: "api/",
      api:{
        postUser: "create-user",
        getUsers: "users",
        getUsersActivityLog: "users/activity/log",
        deleteUser: "user/delete/",
        statusUpdate : "user/set_status/",
        getUser : "user/",
        getLogs : "get/user/logs/",
        logoutuser : "logout/user/",
        updateUser : "user/update/",
        changePassword : "user/change-password/",
        updateProfilePassword : "user/updateProfilePassword",
        updateprofile : "user/updateprofile/",
        getOperators : "users/withoutpaginatebyroleid/",
        getSettingByID : "settings/",
        updateSettingById : "settings/edit/",
        getMerchantGatewayDetailById : "gatewaydetail/user/",
        getMerchantDetailById : "merchantdetail/user/",
        updateMerchantProfileById : "merchant_update_profile/user/",
        updateauxvaultMerchantProfileById: "auxvault_merchant_update_profile/user/",
        updateMerchantGatewayDetailById : "gatewaydetail/save",
        updateAuxvaultMerchantGatewayDetailById: "auxvault/gatewaydetail/save",
        upgradePlan : "user/planupgradation",
        getMasterPassword : "settings/1",
        getSetting : "settings/",
        gatewaydetail : "gatewaydetail/user/",
        updateconveniencefees : "gatewaydetail/update/conveniencefees",
        updateVirtualInfo : "gatewaydetail/update/update_virtual_info",
        gettTransactionReport : "getpaymenttransaction",
        getsendpaymentremainder : "authvia/payment/remainder",
        getcancelrequestpayment : "authvia/payment/cancel",
        resendrequestpayment  : "authvia/payment/resend",
        getHelpdesk : "settings/gethelpdesk",
        setHelpdesk : "settings/sethelpdesk",
        submitHelpdesk:"settings/submithelpdesk",
        getfeesdetails : "user/getfees/",
        submitfeesdetails:"user/fees/update/",
        getStatementData:"user/get/statement/",
      }
    },
    authvia:{
      baseUrl: "api/authvia",
      api:{
        createMerchantAccount : "/merchant/create/",
        createCOnversation : "/payment/conversation/",
        getPaymentGateway : "/list/payment/gateways/",
        updateGatewayStatus : "/payment/updateGatewayStatus"
      }
    },
    mfa:{
      baseUrl: "api/",
      api:{
        addMFA : "user/add/mfa",
        updateMFA : "user/update/mfa/",
        unblockMFA:"user/unblock/mfa/",
        resetMFA:"user/reset/mfa/",
        getMFA:"user/get/mfa/",
        
      }
    },
    invoicehistory:{
      baseUrl: "api",
      api:{
        generatorStatementAll : "/generator/statement/all",
        getInvoicehistory : "/get/invoicehistory",
        invoicehistoryDelete : "/invoicehistory/delete/",
        invoicehistorySendEmail : "/invoicehistory/send/email",
        getuserInvoicehistory : "/generator/statement/user",
      }
    },
    auxvault:{
      baseUrl: "api/auxvault",
      api:{
        createMerchantAccount : "/merchant/create/",
        createCOnversation : "/payment/conversation/",
        getPaymentGateway : "/list/payment/gateways/",
        updateGatewayStatus : "/payment/updateGatewayStatus",
        getsendauxvaultpaymentremainder : "/payment/remainder",
        getauxvaultcancelrequestpayment : "/payment/cancel",
        getauxvaultMerchantDetailById: "/merchantdetail/user/",
        resendauxvaultrequestpayment: "/payment/resend",
        getPaymentDetails: "/payment/get/details/",
        sendpaymentdetailsbymail: "/payment/send/details/bymail/",
      }
    },
    ivr:{
      baseUrl: "api/ivr",
      api:{
        getivrdata : "/getdata/user/",
        sendmessage: "/send/message",
        sendpaymentlink: "/send/paymentlink",
        getivrtemplate: "/get/template/",
      }
    },
    ivrtemplate:{
      baseUrl: "api/",
      api:{
        addTemplate: "ivrtemplates/save",
        getAllTemplates: "ivrtemplates",
        deleteTemplate: "ivrtemplates/delete/",
        getUserTemplates : "ivrtemplates/user/",
        getTemplate : "ivrtemplates/",
        updateTemplate : "ivrtemplates/edit/",
      }
    },
    dms:{
      baseUrl: "api/ams",
      api:{
        getdmsdata : "/getdata/user/",
        sendmessage: "/send/message",
        sendpaymentlink: "/send/paymentlink",
        getdmstemplate: "/get/template/",
      }
    },
    amstemplate:{
      baseUrl: "api/",
      api:{
        addTemplate: "amstemplates/save",
        getAllTemplates: "amstemplates",
        deleteTemplate: "amstemplates/delete/",
        getUserTemplates : "amstemplates/user/",
        getTemplate : "amstemplates/",
        updateTemplate : "amstemplates/edit/",
      }
    },
    keyword:{
      baseUrl: "api/",
      api:{
        postKeyword: "keyword/save",
        getKeywords: "keywords",
        deleteKeyword: "keyword/delete/",
        getKeyword : "keyword/",
        updateKeyword : "keyword/edit/",
      }
    },
    blockkeywords:{
      baseUrl: "api/",
      api:{
        postKeyword: "blockkeywords/save",
        getKeywords: "blockkeywords",
        deleteKeyword: "blockkeywords/delete/",
        getKeyword : "blockkeywords/",
        updateKeyword : "blockkeywords/edit/",
      }
    },
    campaign:{
      baseUrl: "api/",
      api:{
        addCampaign: "campaign/save",
        getCampaigns: "campaign",
        deleteCampaign: "campaign/delete/",
        getCampaign : "campaign/",
        updateCampaign : "campaign/edit/",
        getCampaignReports : "campaign/reports",
        sendMessage : "campaign/sendmessage",
      }
    },
    campaigntemplate:{
      baseUrl: "api/",
      api:{
        addTemplate: "campaigntemplates/save",
        getAllTemplates: "campaigntemplates",
        deleteTemplate: "campaigntemplates/delete/",
        getUserTemplates : "campaigntemplates/user/",
        getTemplate : "campaigntemplates/",
        updateTemplate : "campaigntemplates/edit/",
      }
    },
    department:{
      baseUrl: "api/",
      api:{
        postDepartment: "departments/save",
        getDepartments: "departments",
        deleteDepartment: "departments/delete/",
        getDepartment : "departments/",
        updateDepartment : "departments/edit/",
        getUserDepartments : "departments/user/",
      }
    },
    menusettting:{
      baseUrl: "api/",
      api:{
        postMenusettting: "menusettings/save",
        getMenusetttings: "menusettings",
        deleteMenusettting: "menusettings/delete/",
        getMenusettting : "menusettings/",
        updateMenusettting : "menusettings/edit/",
        getUserMenusettting : "menusettings/user/",
        getUserMenusetttingWithMFA : "menusettings/user/withMFA/",
        getUserparentMenusettting : "menusettings/user/parent/",
        getParentMenusettting: "menusettings/getparentmenu",
        getAllMenusettting: "menusettings/alllist",
        updateUserMenuSettting: "menusettings/user/update",
        getUserMenusetttingformobile:"menusettings/user/formobile/",
        userresendotp: "menusettings/user/resendotp/",
        userotpverify:"menusettings/user/otpverify/",
        usersendotp:"menusettings/user/sendotp/",
        
      }
    },
    emailtemplates:{
      baseUrl: "api/",
      api:{
        postEmailtemplate: "emailtemplates/save",
        getEmailtemplates: "emailtemplates",
        deleteEmailtemplate: "emailtemplates/delete/",
        getEmailtemplate : "emailtemplates/",
        updateEmailtemplate : "emailtemplates/edit/",
        getUserEmailtemplates : "emailtemplates/user/",
        setdefualtEmailtemplates : "emailtemplates/setdefualt/",
      }
    },
    didlocation:{
      baseUrl: "api/",
      api:{
        postDidlocation: "didlocation/save",
        getDidlocations: "didlocation",
        deleteDidlocation: "didlocation/delete/",
        getDidlocation : "didlocation/",
        updateDidlocation : "didlocation/edit/",
        getUserDidlocation : "didlocation/user/",
        setdefualtDidlocation : "didlocation/setdefualt/",
      }
    },
    template:{
      baseUrl: "api/",
      api:{
        addTemplate: "templates/save",
        getAllTemplates: "templates",
        deleteTemplate: "templates/delete/",
        getUserTemplates : "templates/user/",
        getTemplate : "templates/",
        updateTemplate : "templates/edit/",
        templateWithoutePaginate : "templates/withoutpaginate",
      }
    },

    contacts:{
      baseUrl: "api/",
      api:{
        addContact: "accounts/save",
        getContatcs: "contents",
        deleteContact: "contents/delete/",
        getContact : "contents/",
        updateContact : "contents/edit/",
        updateContactInfo : "contents/number",
        updateStatus : "contents/",
        blockContact : "contents/block",
        getcontentwithoutpaginate : "getcontentwithoutpaginate/",
        getcontentByNumber : "contents/getcontemtbynumber",
        importNumber : "contents/import",


      }
    },
    contactScript:{
      baseUrl: "api/",
      api:{
        websites: "websites",
        saveScript: "websites/save",
        getScript : "websites/",
        updateScript : "websites/edit/",
        updateContactInfo : "contents/number",
        updateStatus : "contents/",
        blockContact : "contents/block",
        getcontentwithoutpaginate : "getcontentwithoutpaginate/",
        deleteScript : "websites/delete/"
      }
    },
    groups:{
      baseUrl: "api/",
      api:{
        getGroupList : "groups",
        addGroup: "groups/save",
        getGroup: "groups/",
        deleteGroup: "groups/delete/",
        updateGroup : "groups/edit/",
        associateGroupToUser : "user/adduseringroup"
      }
    },
    didnumbers:{
      baseUrl: "api/",
      api:{
        addContact: "accounts/save",
        getContatcs: "accounts",
        deleteContact: "accounts/delete/",
        setdefaultContact:"accounts/setdefault/",
        getContact : "accounts/",
        updateContact : "accounts/edit/",
        updateStatus : "accounts/",
        downloadDids : "accounts/download/",
        importNumber : "accounts/import",
        listAccounts : "users/withoutpaginate",
        assignDepartment : "accounts/department/update",
        diassignDepartment : "accounts/deassigned/department/",
        getUserDidDepartment : "accounts/getuser/department/",
        getavailableNumbers :"accounts/bandwidth/getavailablenumbers",
        ordersNumbers :"accounts/bandwidth/ordersNumbers",
        searchDID:"accounts/searchdid",
      }
    },
    batch:{
      baseUrl: "api/",
      api:{
        importbatch : "batch/import",
        sendpaymentbatch : "batch/send/payment",
        gettbatchReport : "batch/get",
      }
    },
    conversation:{
      baseUrl: "api/",
      api:{
        sendMessage: "sendMessage",
        sendMessagev1: "v1/sendMessage",
        internalNote:"internalNote",
        addInformation: "conversationUpdate",
        getAccounts: "accounts/",
        getAccountsv1: "v1/accounts/",
        getConversationsforiframe: "conversationThreadforiframe/",
        getConversations: "conversationThread/",
        getConversationsv1: "v1/conversationThread/",
        getConversationswithoutsearch: "conversationThread/withoutsearch/",
        getUnreadCount : "getUnreadCount/",
        getNegativeReviewCount : "getNegativeReviewCount/",
        getChat: "conversationMessage/",
        printChat: "conversationMessage/print/",
        deleteMessage: "deleteConversationThread/",
        deleteConversation : "deleteConversation/",
        getKeyword : "keyword/",
        updateKeyword : "keyword/edit/",
        sendMessageWithoutBandwidth : "sendpaymentMessage",
        sendMessagewithBandwidth : "sendpaymentMessagewithBandwidth",
        updateConversationReadStatus : "conversation/message/status",
        updateConversationLockStatus : "conversation/message/lock",
        sendScheduleMessage: "sendScheduleMessage",
        updateLockStatusAutomatic : "conversation/updateLockStatus/",
        importmessage: "conversation/import/message/file",
         // For Facebook  Cnversation 
         getFacebokPageConversations : "getConversation/",
         markasUnread:"conversation/markasUnread/"
      }
    },
    whatsappconversation:{
      baseUrl: "api/",
      api:{
        sendMessage: "whatsapp/sendMessage",
        internalNote:"whatsapp/internalNote",
        addInformation: "whatsapp/conversationUpdate",
        getAccounts: "whatsapp/accounts/",
        getWhatsappConversation: "whatsapp/conversationThread/",
        getWhatsappConversationwithoutsearch: "whatsapp/conversationThread/withoutsearch/",
        getUnreadCount : "whatsapp/getUnreadCount/",
        getChat: "whatsapp/conversationMessage/",
        deleteMessage: "deleteConversationThread/",
        deleteWhatsappConversation : "whatsapp/deleteConversation/",
        sendMessageWithoutBandwidth : "whatsapp/sendpaymentMessage",
        sendMessagewithBandwidth : "whatsapp/sendpaymentMessagewithBandwidth",
        updateWhatsappConversationReadStatus : "whatsapp/conversation/message/status",
        updateWhatsappConversationLockStatus : "whatsapp/conversation/message/lock",
        sendScheduleMessage: "whatsapp/sendScheduleMessage",
        updateLockStatusAutomatic : "whatsapp/conversation/updateLockStatus/",
        importmessage: "whatsapp/conversation/import/message/file",
        markasUnread:"whatsapp/conversation/markasUnread/"
      }
    },
    groupconversation:{
      baseUrl: "api/",
      api:{
        sendMessage: "groups/send",
        addInformation: "conversationUpdate",
        getAccounts: "accounts/",
        getGroups: "groups/",
        getGroupconversation: "getgroupconversation",
        getGroupChat: "groups/getgroupconversationmessage/",
        deleteMessage: "groupsdeletemessage/",
        deleteConversation : "groupsdeleteallmessage/",
        getKeyword : "keyword/",
        updateKeyword : "keyword/edit/",

      }
    },
    client:{
      baseUrl: "client",
      api:{
        postClient: "client/",
        statusUpdate : "client/set_status/",
        getPasswordResetToken : "client/:id/get_password_reset_token/"
      }
    },
    blocked:{
      baseUrl: "api/",
      api:{
        addBlockedNumber: "blockeds/save",
        listBlockedUSers: "blockeds",
        deleteBlockedContact: "blockeds/delete/",
      }
    },

    autoreply:{
      baseUrl: "api/",
      api:{
        deleteAutoReply: "autoreplymessage/delete/",
        getAutoreplyMessages: "autoreplymessages/user",
        getofficeHours: "officeHours/user",
        updateOfficeHours : "officeHours/save/",
        updateAutoReply : "autoreplymessage/save",
        officeHourstatusUpdate : "officeHourstatus/update/",
        getTimeZone : "getTimeZone",
        getGeneralAutoreplyMessages: "generalautoreplymessages/user",
        updateGeneralAutoReply : "generalAutoreplymessage/save",
        updateGeneralAutoReplyById : "generalAutoreplymessage/update",
        getGeneralAutoreplyMessagesById: "generalautoreplymessages",

      }
    },

    sechedulemessages:{
      baseUrl: "api/",
      api:{
        postScheduleMessage: "shedulemessaeges/save",
        getScheduleMessages: "shedulemessaeges",
        deleteScheduleMessage: "shedulemessaeges/delete/",
        updateScheduleMessage: "shedulemessaeges/edit/",
      }
    },
    reviews:{
      baseUrl: "",
      api:{
        updateGoogeReviewSetiings: "reviewsetting/save",
        resetGoogleReviewAccount: "reviewsetting/reset/",
        getGoogleReviewSettingByUser  : "reviewsetting/user/",
        updateGoogeReviews : "reviewsetting/updateReview",
        getNegativeReviewListByUser : "reviewsettingsWithPagination",
        sendReply : "sendReply"
      }
    },
    remoteAccess:{
      baseUrl: "",
      api:{
        getApiKeyByUserId: "api/credentials/getbyuser/",
        saveApiKeyByUserId : "api/credentials/savebyuserid"
      }
    },
    googleSignIn:{
      baseUrl: "",
      api:{
        getRefreshToken: "getRefreshToken",
        updateLocation : "updateLocation"
      }
    },
    facebookSignIn:{
      baseUrl: "",
      api:{
        getFacebookSetting: "getfacebooksetting/",
        updateFacbookSetting : "updateFacebookSetting",
        updateFBPage: "updateFBPage"
      }
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
