import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SessionStorageService } from './../shared/services/session-storage.service';
import { SharedService } from './../services/shared.service';
declare const $: any;
import { MenusetttingsService } from './../services/menu-setttings.service';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
    roleId: any;
    plan : string;
    activekey: any;


}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
    plan : string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
    roleId : 1,
    plan : 'any',
    activekey: 2,
},{
    path: '/accounts',
    title: 'Account Management',
    type: 'link',
    icontype: 'peoples_alt',
    roleId : 1,
    plan : 'any',
    activekey: 1,

},{
    path: '/did-management',
    title: "DID management",
    type: 'link',
    icontype: 'assignment_ind',
    roleId : 1,
    plan : 'any',
    activekey: 1,
},{
    path: '/helpdesk',
    title: 'Helpdesk',
    type: 'link',
    icontype: 'contact_support',
    roleId : 1,
    plan : 'any',
    activekey: 1,
    
},{
    path: '/billing',
    title: 'Billing',
    type: 'link',
    icontype: 'receipt',
    roleId : 1,
    plan : 'any',
    activekey: 1,
    
},{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
    roleId : 2,
    plan : 'any',
    activekey: 0,

},{
    path: '/conversation',
    title: 'Conversation',
    type: 'link',
    icontype: 'chat',
    roleId : 2,
    plan : 'any',
    activekey: 1,
},
{
    path: '/group-conversation',
    title: 'Group Conversation',
    type: 'link',
    icontype: 'supervised_user_circle',
    roleId : 2,
    plan : 'any',
    activekey: 1,
},
{
    path: '/conversation/model/new',
    title: 'New Message',
    type: 'link',
    icontype: 'person_add',
    roleId : 2,
    plan : 'any',
    activekey: 1,
}
,{
    path: '/conversation/send/bulk/message',
    title: 'Send Bulk Messages',
    type: 'link',
    icontype: 'groups',
    roleId : 2,
    plan : 'any',
    activekey: 1,
}
,{
    path: '/facebook/messages',
    title: 'Facebook Messages',
    type: 'link',
    icontype: 'facebook',
    roleId : 2,
    plan : 'any',
    activekey: 1,
},{
    path: '/scheduleMessages',
    title: 'Schedule Messages',
    type: 'link',
    icontype: 'schedule',
    roleId : 2,
    plan : '2',
    activekey: 1,
}
,{
    path: '/contacts/single',
    title: 'Contacts',
    type: 'link',
    icontype: 'people_alt',
    roleId : 2,
    plan : 'any',
    activekey: 2,
},{
    path: '/contacts/groups',
    title: 'Groups',
    type: 'link',
    icontype: 'groups',
    roleId : 2,
    plan : 'any',
    activekey: 2,
},{
    path: '/autoReplies',
    title: 'Hours Operation',
    type: 'link',
    icontype: 'auto_stories',
    roleId : 2,
    plan : '1',
    activekey: 6,
},
{
    path: '/keywords',
    title: 'Keywords Management',
    type: 'link',
    icontype: 'key_visualizer',
    roleId : 2,
    plan : '2',
    activekey: 6,
},
{
    path: '/blockkeywords',
    title: 'Block Keywords',
    type: 'link',
    icontype: 'key_visualizer',
    roleId : 2,
    plan : '2',
    activekey: 6,
},{
    path: '/templates',
    title: 'Templates',
    type: 'link',
    icontype: 'extension',
    roleId : 2,
    plan : '2',
    activekey: 6,
},{
    path: '/g-reviews/google',
    title: "Google Reviews",
    type: 'link',
    icontype: 'reviews',
    roleId : 2,
    plan : 'any',
    activekey: 4,

},{
    path: '/g-reviews/yelp',
    title: "Yelp Reviews",
    type: 'link',
    icontype: 'reviews',
    roleId : 2,
    plan : 'any',
    activekey: 4,

},{
    path: '/g-reviews/negative',
    title: "Negative Reviews",
    type: 'link',
    icontype: 'public',
    roleId : 2,
    plan : 'any',
    activekey: 4,

},{
    path: '/virtual-terminal',
    title: 'Payments',
    type: 'link',
    icontype: 'paid',
    roleId : 2,
    plan : 'any',
    activekey: 3,
},{
    path: '/batch-upload',
    title: 'Batch Upload',
    type: 'link',
    icontype: 'upload_file',
    roleId : 2,
    plan : 'any',
    activekey: 3,
},{
    path: '/helpdesk',
    title: 'Helpdesk',
    type: 'link',
    icontype: 'contact_support',
    roleId : 2,
    plan : 'any',
    activekey: 5,
},{
    path: '/settings',
    title: 'Settings',
    type: 'link',
    icontype: 'settings',
    roleId : 2,
    plan : 'any',
    activekey: 7,
},{
    path: '/contact-us-scripts',
    title: 'Contact Us Scripts',
    type: 'link',
    icontype: 'branding_watermark',
    roleId : 2,
    plan : 'any',
    activekey: 7,
},{
    path: '/payment-settings',
    title: 'Payment settings',
    type: 'link',
    icontype: 'price_check',
    roleId : 2,
    plan : 'any',
    activekey: 7,
},{
    path: '/reviews-settings',
    title: 'Reviews settings',
    type: 'link',
    icontype: 'rate_review',
    roleId : 2,
    plan : 'any',
    activekey: 7,
},{
    path: '/campaign',
    title: 'Campaign',
    type: 'link',
    icontype: 'link',
    roleId : 2,
    plan : 'any',
    activekey: 8,
},{
    path: '/campaign/report',
    title: 'Report',
    type: 'link',
    icontype: 'report',
    roleId : 2,
    plan : 'any',
    activekey: 8,
},{
    path: '/campaign/sendmessage',
    title: 'Send Message',
    type: 'link',
    icontype: 'chat',
    roleId : 2,
    plan : 'any',
    activekey: 8,
},{
    path: '/campaign-template',
    title: 'Campaign Template',
    type: 'link',
    icontype: 'extension',
    roleId : 2,
    plan : 'any',
    activekey: 8,
},{
    path: '/ivr-details',
    title: 'IVR',
    type: 'link',
    icontype: 'dataset',
    roleId : 2,
    plan : 'any',
    activekey: 9,
},{
    path: '/ivr-template',
    title: 'IVR Template',
    type: 'link',
    icontype: 'extension',
    roleId : 2,
    plan : 'any',
    activekey: 9,
},{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
    roleId : 3,
    plan : 'any',
    activekey: 0,

},{
    path: '/conversation',
    title: 'Conversation',
    type: 'link',
    icontype: 'chat',
    roleId : 3,
    plan : 'any',
    activekey: 1,
},
{
    path: '/group-conversation',
    title: 'Group Conversation',
    type: 'link',
    icontype: 'supervised_user_circle',
    roleId : 3,
    plan : 'any',
    activekey: 1,
},
{
    path: '/conversation/model/new',
    title: 'New Message',
    type: 'link',
    icontype: 'person_add',
    roleId : 3,
    plan : 'any',
    activekey: 1,
}
,{
    path: '/conversation/send/bulk/message',
    title: 'Send Bulk Messages',
    type: 'link',
    icontype: 'groups',
    roleId : 3,
    plan : 'any',
    activekey: 1,
}
,{
    path: '/facebook/messages',
    title: 'Facebook Messages',
    type: 'link',
    icontype: 'facebook',
    roleId : 3,
    plan : 'any',
    activekey: 1,
},{
    path: '/scheduleMessages',
    title: 'Schedule Messages',
    type: 'link',
    icontype: 'schedule',
    roleId : 3,
    plan : '2',
    activekey: 1,
}
,{
    path: '/contacts/single',
    title: 'Contacts',
    type: 'link',
    icontype: 'people_alt',
    roleId : 3,
    plan : 'any',
    activekey: 2,
},{
    path: '/contacts/groups',
    title: 'Groups',
    type: 'link',
    icontype: 'groups',
    roleId : 3,
    plan : 'any',
    activekey: 2,
},{
    path: '/autoReplies',
    title: 'Hours Operation',
    type: 'link',
    icontype: 'auto_stories',
    roleId : 3,
    plan : '1',
    activekey: 6,
},
{
    path: '/keywords',
    title: 'Keywords Management',
    type: 'link',
    icontype: 'key_visualizer',
    roleId : 3,
    plan : '2',
    activekey: 6,
},{
    path: '/templates',
    title: 'Templates',
    type: 'link',
    icontype: 'extension',
    roleId : 3,
    plan : '2',
    activekey: 6,
},{
    path: '/g-reviews/google',
    title: "Google Reviews",
    type: 'link',
    icontype: 'reviews',
    roleId : 3,
    plan : 'any',
    activekey: 4,

},{
    path: '/g-reviews/yelp',
    title: "Yelp Reviews",
    type: 'link',
    icontype: 'reviews',
    roleId : 3,
    plan : 'any',
    activekey: 4,

},{
    path: '/g-reviews/negative',
    title: "Negative Reviews",
    type: 'link',
    icontype: 'public',
    roleId : 3,
    plan : 'any',
    activekey: 4,

},{
    path: '/virtual-terminal',
    title: 'Payments',
    type: 'link',
    icontype: 'paid',
    roleId : 3,
    plan : 'any',
    activekey: 3,
},{
    path: '/batch-upload',
    title: 'Batch Upload',
    type: 'link',
    icontype: 'upload_file',
    roleId : 3,
    plan : 'any',
    activekey: 3,
},{
    path: '/helpdesk',
    title: 'Helpdesk',
    type: 'link',
    icontype: 'contact_support',
    roleId : 3,
    plan : 'any',
    activekey: 5,
    
},{
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard',
    roleId : 4,
    plan : 'any',
    activekey: 0,

},{
    path: '/conversation',
    title: 'Conversation',
    type: 'link',
    icontype: 'chat',
    roleId : 4,
    plan : 'any',
    activekey: 1,
},
{
    path: '/group-conversation',
    title: 'Group Conversation',
    type: 'link',
    icontype: 'supervised_user_circle',
    roleId : 4,
    plan : 'any',
    activekey: 1,
},
{
    path: '/conversation/model/new',
    title: 'New Message',
    type: 'link',
    icontype: 'person_add',
    roleId : 4,
    plan : 'any',
    activekey: 1,
}
,{
    path: '/conversation/send/bulk/message',
    title: 'Send Bulk Messages',
    type: 'link',
    icontype: 'groups',
    roleId : 4,
    plan : 'any',
    activekey: 1,
}
,{
    path: '/facebook/messages',
    title: 'Facebook Messages',
    type: 'link',
    icontype: 'facebook',
    roleId : 4,
    plan : 'any',
    activekey: 1,
},{
    path: '/scheduleMessages',
    title: 'Schedule Messages',
    type: 'link',
    icontype: 'schedule',
    roleId : 4,
    plan : '2',
    activekey: 1,
}
,{
    path: '/contacts/single',
    title: 'Contacts',
    type: 'link',
    icontype: 'people_alt',
    roleId : 4,
    plan : 'any',
    activekey: 2,
},{
    path: '/contacts/groups',
    title: 'Groups',
    type: 'link',
    icontype: 'groups',
    roleId : 4,
    plan : 'any',
    activekey: 2,
},{
    path: '/autoReplies',
    title: 'Hours Operation',
    type: 'link',
    icontype: 'auto_stories',
    roleId : 4,
    plan : '1',
    activekey: 6,
},
{
    path: '/keywords',
    title: 'Keywords Management',
    type: 'link',
    icontype: 'key_visualizer',
    roleId : 4,
    plan : '2',
    activekey: 6,
},{
    path: '/templates',
    title: 'Templates',
    type: 'link',
    icontype: 'extension',
    roleId : 4,
    plan : '2',
    activekey: 6,
},{
    path: '/g-reviews/google',
    title: "Google Reviews",
    type: 'link',
    icontype: 'reviews',
    roleId : 4,
    plan : 'any',
    activekey: 4,

},{
    path: '/g-reviews/yelp',
    title: "Yelp Reviews",
    type: 'link',
    icontype: 'reviews',
    roleId : 4,
    plan : 'any',
    activekey: 4,

},{
    path: '/g-reviews/negative',
    title: "Negative Reviews",
    type: 'link',
    icontype: 'public',
    roleId : 4,
    plan : 'any',
    activekey: 4,

},{
    path: '/virtual-terminal',
    title: 'Payments',
    type: 'link',
    icontype: 'paid',
    roleId : 4,
    plan : 'any',
    activekey: 3,
},{
    path: '/batch-upload',
    title: 'Batch Upload',
    type: 'link',
    icontype: 'upload_file',
    roleId : 4,
    plan : 'any',
    activekey: 3,
},{
    path: '/helpdesk',
    title: 'Helpdesk',
    type: 'link',
    icontype: 'contact_support',
    roleId : 4,
    plan : 'any',
    activekey: 5,
    
} 
];
@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    private readonly _userDetails: string = 'user_details';
    userdetails : any ="";
    userRole : any = "";
    activemenu : any="";
    Selectedchildmenu: any = [];
    constructor(
        
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private sharedService: SharedService,
        private menusetttingsService: MenusetttingsService,
        
    ){
        this.userdetails = JSON.parse(atob(localStorage.getItem(this._userDetails)));
        if(this.userdetails && this.userdetails == undefined){
            this.sharedService.logout();
        }
        this.userRole =  this.userdetails.role_id;
        this.activemenu = this.sharedService.getactivemenu();
        
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.activemenu = this.sharedService.getactivemenu();
        if (this.userdetails.role_id <= 2) {
            this.getUserMenusetttings(this.userdetails.id);
          } else {
            this.getUserMenusetttings(this.userdetails.user_id);
          }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    checkmenu(){
        this.activemenu = this.sharedService.getactivemenu();
    }

    isActive(menu){
        let isactivemenu = true;
        if(menu.roleId == '4'){
            switch (menu.title) {
                case 'Google Reviews':
                    isactivemenu = this.userdetails.googlereviewAccess == 1 ? true : false;
                    break;
                    
                case 'Yelp Reviews':
                    isactivemenu = this.userdetails.yelpAccess == 1 ? true : false;
                    break;
                
                case 'Batch Upload':
                    isactivemenu = this.userdetails.batchUpload == 1 ? true : false;
                    break;

                case 'Facebook Messages':
                    isactivemenu = this.userdetails.fecebookAccess == 1 ? true : false;
                    break;
                
                default:
                 isactivemenu = true;
                    break;
            }
        }
        //console.log(isactivemenu);
        return isactivemenu;
    }

    logout(){
        this.sharedService.logout();
    }

    getUserMenusetttings(id) {
        if(this.isMobileMenu()){
            this.menusetttingsService
          .getUserMenusetttingformobile(id)
          .pipe()
          .subscribe(
            (result: any) => {
              this.Selectedchildmenu = result.data.menulist;
              localStorage.setItem('Selectedmenu', JSON.stringify(this.Selectedchildmenu));
            }
          );

        }else{
            this.menusetttingsService
          .getUserMenusettting(id)
          .pipe()
          .subscribe(
            (result: any) => {
              this.Selectedchildmenu = result.data.menulist;
              localStorage.setItem('Selectedmenu', JSON.stringify(this.Selectedchildmenu));
            }
          );
        }
        
      }

}
