import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpService } from "../shared/services/http.service";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  private readonly apiUsersBaseUrl =
    environment.services.gatewayUrl + environment.services.conversation.baseUrl;
  private readonly apiAuthsBaseUrl =
    environment.services.gatewayUrl + environment.services.authvia.baseUrl;
  private readonly apiAuxVaultBaseUrl =
    environment.services.gatewayUrl + environment.services.auxvault.baseUrl;
  constructor( private httpService: HttpService) { }

  public getAccounts(id, role_id): Observable<Response> {
    const url = environment.services.conversation.api.getAccounts+'user/'+id+'/'+role_id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getAccountsV1(id, role_id, type): Observable<Response> {
    const url = environment.services.conversation.api.getAccountsv1+'user/'+id+'/'+role_id+'/'+type;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getConversationData(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getConversations+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public getConversationDataV1(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getConversationsv1+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  
  public getConversationDatawithoutsearch(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getConversationswithoutsearch+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  
  public getConversationforiframeData(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getConversationsforiframe+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  
  public getUnreadCount(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getUnreadCount+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  
  public getNegativeReviewCount(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getNegativeReviewCount+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public getChat(id, data): Observable<Response> {
    const url = environment.services.conversation.api.getChat+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url ,data);
  }

  public printChat(id, data): Observable<Response> {
    const url = environment.services.conversation.api.printChat+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url ,data);
  }

  public markasUnread(id): Observable<Response> {
    const url = environment.services.conversation.api.markasUnread+id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }
  
  
  public importmessage(data): Observable<Response> {
    const url = environment.services.conversation.api.importmessage;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  
  public sendMessage(data): Observable<Response> {
    const url = environment.services.conversation.api.sendMessage;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public sendMessageV1(data): Observable<Response> {
    const url = environment.services.conversation.api.sendMessagev1;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public internalNote(data): Observable<Response> {
    const url = environment.services.conversation.api.internalNote;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  

  public sendScheduleMessage(data): Observable<Response> {
    const url = environment.services.conversation.api.sendScheduleMessage;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  

  public deleteMessage(id): Observable<Response> {
    const url = environment.services.conversation.api.deleteMessage +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public deleteConversation(id): Observable<Response> {
    const url = environment.services.conversation.api.deleteConversation +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }
  
  public addInformation(data): Observable<Response> {
    const url = environment.services.conversation.api.addInformation;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  
  public updateKeyword(id,data): Observable<Response> {
    const url = environment.services.conversation.api.updateKeyword +id;
    return this.httpService.put<Response>(this.apiUsersBaseUrl, url,data);
  }

  
  public getKeyword(id): Observable<Response> {
    const url = environment.services.conversation.api.getKeyword +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  templateWithoutePaginate(data){
    const url = environment.services.template.api.templateWithoutePaginate;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }


  createPaymentLink(parentUser, data){
    const url = environment.services.authvia.api.createCOnversation + parentUser;
    return this.httpService.post<Response>(this.apiAuthsBaseUrl, url, data);
  }

  createauxvaultPaymentLink(parentUser, data){
    const url = environment.services.auxvault.api.createCOnversation + parentUser;
    return this.httpService.post<Response>(this.apiAuxVaultBaseUrl, url, data);
  }

  public sendMessageWithoutBandwidth(data): Observable<Response> {
    const url = environment.services.conversation.api.sendMessageWithoutBandwidth;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public sendMessagewithBandwidth(data): Observable<Response> {
    const url = environment.services.conversation.api.sendMessagewithBandwidth;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public updateConversationReadStatus(data): Observable<Response> {
    const url = environment.services.conversation.api.updateConversationReadStatus;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  
  public updateConversationLockStatus(data): Observable<Response> {
    const url = environment.services.conversation.api.updateConversationLockStatus;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  
  public updateLockStatusAutomatic(id): Observable<Response> {
    const url = environment.services.conversation.api.updateLockStatusAutomatic +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }



  public getGoogleReview(accountId, locationId, accessToken): Observable<Response> {
    const url = "/accounts/"+accountId+"/locations/"+locationId+"/reviews?access_token="+accessToken;
    return this.httpService.get<Response>("https://mybusiness.googleapis.com/v4", url);
  }

  public getSingleGoogleReview(accountId, locationId, accessToken, reviewId): Observable<Response> {
    const url = "/accounts/"+accountId+"/locations/"+locationId+"/reviews/"+reviewId+"?access_token="+accessToken;
    return this.httpService.get<Response>("https://mybusiness.googleapis.com/v4", url);
  }

  public replyGoogleReview(accountId, locationId, accessToken, reviewId, comment): Observable<Response> {
    const url = "/accounts/"+accountId+"/locations/"+locationId+"/reviews/"+reviewId+"/reply?access_token="+accessToken;
    return this.httpService.put<Response>("https://mybusiness.googleapis.com/v4", url,comment);
  }
  
  public deleteGoogleReviewReply(accountId, locationId, accessToken, reviewId): Observable<Response> {
    const url = "/accounts/"+accountId+"/locations/"+locationId+"/reviews/"+reviewId+"/reply?access_token="+accessToken;
    return this.httpService.delete<Response>("https://mybusiness.googleapis.com/v4", url);
  }

  // Get Facebook Conversations 
  public getFacebookConversationData(id): Observable<Response> {
    const url = environment.services.conversation.api.getFacebokPageConversations+id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }
  
}

