import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { SessionStorageService } from '../shared/services/session-storage.service';
import { AccountsService } from './../services/accounts.service';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  username;
  password;
  totalUnreadConversation : any = 0;
  totalUnreadNegativeReview : any = 0;
  token:any = "";
  activemenu:any ="";

  selectedConversationId : any = "";
  conversationTo : any = "";
  conversationTitle : any = "";
  conversationFrom : any = "";
  accountTitle : any = "";
  googleAccessToken : any = "";
  googleAccessTokenChange: Subject<boolean> = new Subject<boolean>();

  constructor(private sessionStorageService: SessionStorageService, private router: Router, private accountsService: AccountsService) {
     this.username = "";
     this.password = "";

     this.googleAccessTokenChange.subscribe((value) => {
        this.googleAccessToken = value
    });
}

  setusername(val){
    this.username = val;
  }

  setpassword(val){
    this.password = val;
  }

  // getusername(){
  //   return this.username;
  // }
  // getpassword(){
  //   return this.password;
  // }

  logout() {
    
    this.accountsService
      .logout()
      .pipe()
      .subscribe(
        (result: any) => {
          console.log("Loged out");
        },
        (error: any) => {
          console.log("Something went wrong");
        }
      );

      localStorage.removeItem('username');
      var UUID = localStorage.getItem('UUID');

      this.sessionStorageService.flushOnLogout();
      localStorage.clear();
     
        // setTimeout(function(this){
          this.router.navigate(['login']);
      localStorage.setItem('UUID', UUID);
        // },2000);
    
      
      
  }

  settotalUnreadConversation(val){
    this.totalUnreadConversation = val;
  }

  settotalNegativeReview(val){
    this.totalUnreadNegativeReview = val;
  }

  setToken(val){
    this.token = val;
  }

  getToken(){
    return this.token;
  }

  setactivemenu(val){
    this.activemenu = val;
  }

  getactivemenu(){
    return this.activemenu;
  }

  

  updateConversationData(conversationId, conTo, conToNUmber, conFrom, conFromNumber){
    this.selectedConversationId = conversationId;
    this.conversationTo = conTo;
    this.conversationTitle = conToNUmber;
    this.conversationFrom = conFrom;
    this.accountTitle = conFromNumber;
  }

  setGoogleAccessToken(token){
    this.googleAccessTokenChange.next(token);
  }

}
