import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpService } from "../shared/services/http.service";
//import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from "rxjs";
import { map, filter, scan } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  private readonly apiUsersBaseUrl =
    environment.services.gatewayUrl + environment.services.user.baseUrl;
  private readonly apiBaseUrl = environment.services.gatewayUrl;
  private readonly apiAvuthViaBaseUrl =
    environment.services.gatewayUrl + environment.services.authvia.baseUrl;
  private readonly apiAuxVaultBaseUrl =
    environment.services.gatewayUrl + environment.services.auxvault.baseUrl;
    private readonly apiMFABaseUrl =
    environment.services.gatewayUrl + environment.services.mfa.baseUrl;

  constructor( private httpService: HttpService) { }

  // -------------------------------------client---------------------------------------------------

  public listUsers(): Observable<Response> {
    const url = environment.services.user.api.getUsers;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public listUsersPginate(page, data): Observable<Response> {
    const url = environment.services.user.api.getUsers+"?page="+page;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public listUserActivityLog(page, data): Observable<Response> {
    const url = environment.services.user.api.getUsersActivityLog+"?page="+page;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }


  public createUser(data): Observable<Response> {
    const url = environment.services.user.api.postUser;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public createFrontUser(data): Observable<Response> {
    const url = environment.services.auth.api.postUser;
    return this.httpService.post<Response>(this.apiBaseUrl, url, data);
  }


  public deleteUser(id): Observable<Response> {
    const url = environment.services.user.api.deleteUser +id;
    return this.httpService.delete<Response>(this.apiUsersBaseUrl, url);
  }

  public getUser(id): Observable<Response> {
    const url = environment.services.user.api.getUser +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getLogs(id): Observable<Response> {
    const url = environment.services.user.api.getLogs +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public logoutuser(id): Observable<Response> {
    const url = environment.services.user.api.logoutuser +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }


  public updateUser(id,data): Observable<Response> {
    const url = environment.services.user.api.updateUser +id;
    return this.httpService.put<Response>(this.apiUsersBaseUrl, url,data);
  }

  public changeUserPass(id,data): Observable<Response> {
    const url = environment.services.user.api.changePassword +id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  
  public updateProfilePassword(data): Observable<Response> {
    const url = environment.services.user.api.updateProfilePassword;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public updateprofile(id, data): Observable<Response> {
    const url = environment.services.user.api.updateprofile + id;
    return this.httpService.put<Response>(this.apiUsersBaseUrl, url, data);
  }


  public getDashboardData(userID, roleId, data): Observable<Response> {
    const url = environment.services.dashboadrd.api.dashboardData +userID+'/'+roleId;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }




  public createMerchantAccount(userID): Observable<Response> {
    const url = environment.services.authvia.api.createMerchantAccount + userID;
    return this.httpService.get<Response>(this.apiAvuthViaBaseUrl, url);
  }

  public createauxvaultMerchantAccount(userID): Observable<Response> {
    const url = environment.services.auxvault.api.createMerchantAccount + userID;
    return this.httpService.get<Response>(this.apiAuxVaultBaseUrl, url);
  }

  public getSettingById(id): Observable<Response> {
    const url = environment.services.user.api.getSettingByID + id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public updateSettingById(id,data): Observable<Response> {
    const url = environment.services.user.api.updateSettingById +id;
    return this.httpService.put<Response>(this.apiUsersBaseUrl, url, data);
  }

  public getMerchantGatewayDetailById(id): Observable<Response> {
    const url = environment.services.user.api.getMerchantGatewayDetailById + id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public updateMerchantGatewayDetailById(data): Observable<Response> {
    const url = environment.services.user.api.updateMerchantGatewayDetailById;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public updateAuxvaultMerchantGatewayDetailById(data): Observable<Response> {
    const url = environment.services.user.api.updateAuxvaultMerchantGatewayDetailById;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public sendNotification(data): Observable<Response> {
    return this.httpService.post<Response>(environment.services.gatewayUrl, 'api/sendNotification', data);
  }

  public logout(): Observable<Response> {
    return this.httpService.get<Response>(environment.services.gatewayUrl, 'logout');
  }

  public upgradePlan(data): Observable<Response> {
    const url = environment.services.user.api.upgradePlan;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public getmasterPassword(): Observable<Response> {
    const url = environment.services.user.api.getMasterPassword;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getSetting(id): Observable<Response> {
    const url = environment.services.user.api.getSetting+id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getPaymentList(userID): Observable<Response> {
    const url = environment.services.authvia.api.getPaymentGateway + userID;
    return this.httpService.get<Response>(this.apiAvuthViaBaseUrl, url);
  }

  public getauxvaultPaymentList(userID): Observable<Response> {
    const url = environment.services.auxvault.api.getPaymentGateway + userID;
    return this.httpService.get<Response>(this.apiAuxVaultBaseUrl, url);
  }
  

  public gatewaydetail(userID): Observable<Response> {
    const url = environment.services.user.api.gatewaydetail + userID;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }


  public updateconveniencefees(data): Observable<Response> {
    const url = environment.services.user.api.updateconveniencefees;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  updateGatewayStatus(data): Observable<Response> {
    const url = environment.services.authvia.api.updateGatewayStatus;
    return this.httpService.post<Response>(this.apiAvuthViaBaseUrl, url, data);
  }

  public updateVirtualInfo(data): Observable<Response> {
    const url = environment.services.user.api.updateVirtualInfo;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public gettTransactionReport(page, data): Observable<Response> {
    const url = environment.services.user.api.gettTransactionReport+"?page="+page;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public updateGoogleReviewSetting(data): Observable<Response> {
    const url = environment.services.reviews.api.updateGoogeReviewSetiings;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  
  public getGoogleReviewSettingByUser(userID): Observable<Response> {
    const url = environment.services.reviews.api.getGoogleReviewSettingByUser + userID;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getAcceessTokenUsingRefresh(data): Observable<Response> {
    const url = environment.services.reviews.api.updateGoogeReviewSetiings;
    return this.httpService.post<Response>("https://oauth2.googleapis.com", "/token", data);
  }

  
  public updateUserReview(data): Observable<Response> {
    const url = environment.services.reviews.api.updateGoogeReviews;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public getsendremainderapi(data): Observable<Response> {
    const url = environment.services.user.api.getsendpaymentremainder;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public getsendremainderauxvaultapi(data): Observable<Response> {
    const url = environment.services.auxvault.api.getsendauxvaultpaymentremainder;
    return this.httpService.post<Response>(this.apiAuxVaultBaseUrl, url, data);
  }


  public cancelPaymentRequestApi(data): Observable<Response> {
    const url = environment.services.user.api.getcancelrequestpayment;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public cancelPaymentRequestauxvaultApi(data): Observable<Response> {
    const url = environment.services.auxvault.api.getauxvaultcancelrequestpayment;
    return this.httpService.post<Response>(this.apiAuxVaultBaseUrl, url, data);
  }
  
  public viewPaymentDetails(id): Observable<Response> {
    const url = environment.services.auxvault.api.getPaymentDetails +id;
    return this.httpService.get<Response>(this.apiAuxVaultBaseUrl, url);
  }

  
  public sendPaymentDetailsBymail(id, data): Observable<Response> {
    const url = environment.services.auxvault.api.sendpaymentdetailsbymail+id;
    return this.httpService.post<Response>(this.apiAuxVaultBaseUrl, url, data);
  }


  public resendPaymentRequestApi(data): Observable<Response> {
    const url = environment.services.user.api.resendrequestpayment;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public resendPaymentRequestauxvaultApi(data): Observable<Response> {
    const url = environment.services.auxvault.api.resendauxvaultrequestpayment;
    return this.httpService.post<Response>(this.apiAuxVaultBaseUrl, url, data);
  }


  public getMerchantProfile(id): Observable<Response> {
    const url = environment.services.user.api.getMerchantDetailById+id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public getauxvaultMerchantProfile(id): Observable<Response> {
    const url = environment.services.auxvault.api.getauxvaultMerchantDetailById+id;
    return this.httpService.get<Response>(this.apiAuxVaultBaseUrl, url);
  }

  
  public updateMerchantProfileId(data, id): Observable<Response> {
    const url = environment.services.user.api.updateMerchantProfileById+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  public updateauxvaultMerchantProfileId(data, id): Observable<Response> {
    const url = environment.services.user.api.updateauxvaultMerchantProfileById+id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }


  public getApiKeyByUserId(userID): Observable<Response> {
    const url = environment.services.remoteAccess.api.getApiKeyByUserId+userID;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public saveApiKeyByUserId(data): Observable<Response> {
    const url = environment.services.remoteAccess.api.saveApiKeyByUserId;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  /**
   * Sign in with google to get review
   */
  public getRefreshTokenID(data:any): Observable<Response> {
    const url = environment.services.googleSignIn.api.getRefreshToken;
    console.log(url,data)
    return this.httpService.post<Response>(environment.services.gatewayUrl, url, data);
  }

  public updateLocation(data:any): Observable<Response> {
    const url = environment.services.googleSignIn.api.updateLocation;
    console.log(url,data)
    return this.httpService.post<Response>(environment.services.gatewayUrl, url, data);
  }


  /**
   * Helpdesk apis
   */

  public getHelpdesk(): Observable<Response> {
    const url = environment.services.user.api.getHelpdesk;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  
  public setHelpdesk(data): Observable<Response> {
    const url = environment.services.user.api.setHelpdesk;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  
  public submitHelpdesk(data): Observable<Response> {
    const url = environment.services.user.api.submitHelpdesk;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }
  /**
   * fees apis
   */

  public getfeesdetails(id): Observable<Response> {
    const url = environment.services.user.api.getfeesdetails +id;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public submitfeesdetails(id,data): Observable<Response> {
    const url = environment.services.user.api.submitfeesdetails +id;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url,data);
  }

  public getStatementData(userID, data): Observable<Response> {
    const url = environment.services.user.api.getStatementData +userID;
    return this.httpService.post<Response>(this.apiUsersBaseUrl, url, data);
  }

  /**
   * Connect Facebook to get reviews
   */

   public getFacebookReviewSettingByUser(userID): Observable<Response> {
    const url = environment.services.facebookSignIn.api.getFacebookSetting + userID;
    return this.httpService.get<Response>(this.apiUsersBaseUrl, url);
  }

  public updateFacebookSettingByUser(data:any): Observable<Response> {
    const url = environment.services.facebookSignIn.api.updateFacbookSetting;
    console.log(url,data)
    return this.httpService.post<Response>(environment.services.gatewayUrl, url, data);
  }

  public updateFBPage(data:any): Observable<Response> {
    // const url = environment.services.facebookSignIn.api.updateFBPage;
    // console.log(url,data)
    return this.httpService.post<Response>(environment.services.gatewayUrl, "", data);
  }

  public ChangePage(data:any):Observable<Response>{

    return this.httpService.post<Response>(environment.services.gatewayUrl, "setPageSetting", data);
  }

  

  public unblockTwoFactorVerification(id):Observable<Response>{
    const url = environment.services.mfa.api.unblockMFA +id;
    return this.httpService.get<Response>(this.apiMFABaseUrl, url);
  }

  public resetTwoFactorVerification(id):Observable<Response>{
    const url = environment.services.mfa.api.resetMFA +id;
    return this.httpService.get<Response>(this.apiMFABaseUrl, url);
  }

  public AddMFA(data:any): Observable<Response> {
    const url = environment.services.mfa.api.addMFA;
    return this.httpService.post<Response>(this.apiMFABaseUrl, url, data);
  }

  public updateMFA(id, data): Observable<Response> {
    const url = environment.services.mfa.api.updateMFA+id;
    return this.httpService.post<Response>(this.apiMFABaseUrl, url, data);
  }

  public getMFA(id): Observable<Response> {
    const url = environment.services.mfa.api.getMFA+id;
    return this.httpService.get<Response>(this.apiMFABaseUrl, url);
  }

  


}
