<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="./assets/img/favIcon.png" />
    </div>
  </a>
  <a routerLink="/dashboard" class="simple-text logo-normal">
    AuxCHAT
  </a>
</div>
<div class="sidebar-wrapper">
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/profile']" href="javascript:void(0)">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Profile</span>
          </p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="logout()">
          <i class="material-icons">logout</i>
          <p>
            <span class="d-lg-none d-md-block">Logout</span>
          </p>
        </a>
      </li>

    </ul>
  </div>
  <ul class="nav" *ngIf="!isMobileMenu()">
    <li routerLinkActive="active" *ngFor="let menuitem of Selectedchildmenu" class="nav-item" >
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]"
        *ngIf="menuitem.parent_id == activemenu"
        class="nav-link menuclass" >
        <i class="material-icons">{{menuitem.icon}}</i>
        <p>{{menuitem.name}}</p>
      </a>
    </li>
  </ul>
  <ul class="nav" *ngIf="isMobileMenu()">
    <li routerLinkActive="active" *ngFor="let menuitem of Selectedchildmenu" class="nav-item" >
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]"
        *ngIf="menuitem.parent_id == 0 && !menuitem.child.length"
        class="nav-link menuclass" >
        <i class="material-icons">{{menuitem.icon}}</i>
        <p>{{menuitem.name}}</p>
      </a>

      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#id_{{menuitem.id}}"
        *ngIf="menuitem.child.length"  class="nav-link">
        <i class="material-icons">{{menuitem.icon}}</i>
        <p>{{menuitem.name}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="id_{{menuitem.id}}" class="collapse" *ngIf="menuitem.child.length" >
        <ul class="nav">
          <ng-container *ngFor="let childitem of menuitem.child">
            <li routerLinkActive="active" class="nav-item" >
              <a [routerLink]="[childitem.path]" class="nav-link">
                <!-- <span class="sidebar-mini">{{childitem.icon}}</span> -->
                <span class="sidebar-normal">{{childitem.name}}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </li>
   
  </ul>

</div>